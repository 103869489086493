/* eslint-disable complexity, sonarjs/cognitive-complexity */
/* stylelint-disable property-no-vendor-prefix */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledRangeSliderProps } from './RangeSlider.style.types'

type RangeSliderProps = Pick<StyledRangeSliderProps, 'state' | 'theme' | 'hasLabels' | 'hasTextInput'>
export const RangeSlider = styled.div((props: RangeSliderProps): FlattenSimpleInterpolation => css`
    padding: 6px 0;

    ${props.hasLabels && css`
      padding-bottom: 28px;
    `}

    ${props.hasTextInput && css`
      padding: 0px;

      ${props.hasLabels && css`
        padding-bottom: 12px;
      `}
    `}

    .rc-slider-handle {
      border: none;
      background-color: ${props.theme.color.primary1.default};
      width: 24px;
      height: 24px;
      top: 0;
      z-index: 2;

      &:active,:hover {
        box-shadow: 0 0 5px ${props.theme.color.monochrome6.default};
      }

      ${props.state === 'disabled' && css`
        background-color: ${props.theme.color.monochrome3.default};

        &:active,:hover {
          box-shadow: none;
        }
      `}
    }

    .rc-slider-rail {
      height: 8px;
      background-color: ${props.theme.color.monochrome5.default};

      ${props.state === 'disabled' && css`
        background-color: ${props.theme.color.monochrome3.default};
      `}
    }

    .rc-slider-track {
      z-index: 1;
      height: 8px;
      background-color: ${props.theme.color.primary1.default};
    }

    .rc-slider-tooltip {
      z-index: 3;
    }

    .rc-slider-tooltip-inner {
      background-color: ${props.theme.color.monochrome5.default};
      border-radius: 4px;
      padding: 8px 16px;
      height: initial;
      font-size: ${props.theme.typography.paragraph[2].fontDesktop};
    }

    .rc-slider-tooltip-arrow {
      border-top-color: ${props.theme.color.monochrome5.default};
    }

    .rc-slider-dot {
      bottom: -2px;
      height: 4px;
      width: 4px;
      margin-left: -3px;
      border: none;
      background-color: ${props.theme.color.monochrome4.default};

      ${props.state === 'disabled' && css`
        background-color: ${props.theme.color.monochrome3.default};
      `}

      &:first-child,:last-child {
        display: none;
      }
    }

    .rc-slider-mark-text {
      white-space: pre;
      color: ${props.theme.color.monochrome5.default};
      font-size: ${props.theme.typography.paragraph[1].fontDesktop};

      ${props.state === 'disabled' && css`
        color: ${props.theme.color.monochrome3.default};
      `}

      &:first-child {
        transform: translateX(0%) !important;
      }

      &:last-child {
        transform: translateX(-100%) !important;
      }
    }
`)

type SliderWithInputProps = Pick<StyledRangeSliderProps, | 'hasPrefix'>
export const SliderWithInput = styled.div((props: SliderWithInputProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;

  > [class*='TextInputWrapper'] {
    width: 72px;
    margin-left: 24px;

    ${props.hasPrefix && css`
      margin-left: 0;
    `}
  }

  > [class*='TextInputSuffix'] {
    margin-left: 4px;
  }

  > [class*='TextInput'] {
    input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    [class*='IconWrapper'] {
      display: none;
    }
  }
`)

export const SliderWithInputContainer = styled.div((): FlattenSimpleInterpolation => css`
  width: 100%;

  > .rc-slider {
    padding: 3px 0;

    > .rc-slider-mark {
      top: 26px;
    }
  }
`)

type TextInputPrefixProps = Pick<StyledRangeSliderProps, 'theme'>
export const TextInputPrefix = styled.div((props: TextInputPrefixProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.typography.paragraph(2, false)};
  margin: 0 4px 0 24px;
`)

type TextInputSuffixProps = Pick<StyledRangeSliderProps, 'theme'>
export const TextInputSuffix = styled.div((props: TextInputSuffixProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.typography.paragraph(2, false)};
  margin-left: 4px;
`)

export const SliderWithTooltip = styled.div((): FlattenSimpleInterpolation => css`

  > .rc-slider {
    padding: 3px 0;

    > .rc-slider-mark {
      top: 26px;
    }
  }
`)

type SrLabelProps = Pick<StyledRangeSliderProps, 'theme'>
export const SrLabel = styled.label((props: SrLabelProps) => css`
  ${props.theme.mixins.srOnly()}
`)
