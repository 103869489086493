import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledCardBodyProps } from './CardBody.style.types'

export const CardBody = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${props.theme.mixins.spacing('padding', 3)}
`)

type ContentWrapperHeaderProps = Pick<StyledCardBodyProps, 'theme' | 'hasChevron'>
export const ContentWrapperHeader = styled.div((props: ContentWrapperHeaderProps): FlattenSimpleInterpolation => css`
  margin-bottom: ${props.theme.spacing.fixed[3]}px;

  ${props.hasChevron && css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row-reverse;
  `}

  [class*='Heading'] {
    ${props.hasChevron && css`
      margin-right: ${props.theme.spacing.fixed[3]}px;
    `}
  }

  [class*='Icon'] {
    margin-left: auto;
  }
`)

type ContentWrapperProps = Pick<StyledCardBodyProps, 'theme'>
export const ContentWrapper = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  flex-grow: 1;
  margin-bottom: ${props.theme.spacing.fixed[5]}px;

  &:last-child {
    margin-bottom: 0;
  }
`)

type ButtonWrapperProps = Pick<StyledCardBodyProps, 'theme' | 'centerButtons'>
export const ButtonWrapper = styled.div((props: ButtonWrapperProps): FlattenSimpleInterpolation => css`
  margin-bottom: ${props.theme.spacing.fixed[3]}px;

  [class*='Button'] {
      margin-bottom: ${props.theme.spacing.fixed[3]}px;

      &:last-child {
        margin-bottom: 0;
      }
    }

  ${props.theme.mixins.respondTo.md(css`
    display: flex;
    justify-content: ${props.centerButtons ? 'center' : 'left'};

    [class*='Button'] {
      margin-right: ${props.theme.spacing.fixed[3]}px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  `)}
`)

type LinkWrapperProps = Pick<StyledCardBodyProps, 'theme'>
export const LinkWrapper = styled.div((props: LinkWrapperProps): FlattenSimpleInterpolation => css`
  font-size: ${props.theme.typography.paragraph[1].fontDesktop};
  position: absolute;
  left: 50%;
  bottom: 0;

  button {
    position: relative;
    left: -50%;
  }
`)
