import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledSelectInputProps } from './SelectInput.style.types'

import localTheme from '../themes/SelectInput.theme'

export const SelectInputWrapper = styled.div((): FlattenSimpleInterpolation => css`
  position: relative;
`)

export const SelectInput = styled.select((props: StyledSelectInputProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && localTheme(props.theme).state[props.state]

  return css`
    border-radius: ${props.theme.border.radius[2]};
    color: ${props.theme.color.monochrome5.default};
    background-color: ${props.theme.color.monochrome1.default};
    width: 100%;
    ${props.theme.mixins.advancedSpacing('padding', [2, 0, 2, 3])};
    ${props.theme.mixins.typography.heading(1, false)};
    border: ${props.theme.border.style[1]} ${props.theme.border.width[1]} ${props.theme.color.monochrome3.default};
    appearance: none;
    transition: .4s box-shadow ease;
    box-sizing: border-box;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
    }

    ${(props.state && stateFromTheme) && css`
      color: ${stateFromTheme.color};
      background-color: ${stateFromTheme.backgroundColor};
      border-color: ${stateFromTheme.border.color};
      border-width: ${stateFromTheme.border.width};
    `}

    ${props.state === 'disabled' && css`
      cursor: not-allowed;

      &:focus {
        caret-color: transparent;
      }
    `}
  `
})

export const Option = styled.option((): FlattenSimpleInterpolation => css`
  width: 100%;
`)

export const IconContainer = styled.div((props: StyledSelectInputProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && localTheme(props.theme).state[props.state]

  return css`
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    right: 0;
    ${props.theme.mixins.spacing('padding-right', 3)}
    pointer-events: none;

    ${(props.state === 'disabled' && stateFromTheme) && css`
      [class*='Icon'] {
        * {
          ${props.theme.mixins.iconAppearance(stateFromTheme.color)};
        }
      }
    `}
  `
})
