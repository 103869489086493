import { css, FlattenSimpleInterpolation } from 'styled-components'

const inputElementSuggestions = css`
  ${({ theme }): FlattenSimpleInterpolation => css`
    input:-webkit-autofill::first-line {
      font-family: ${theme.baseFont.fontFamily};
      ${theme.mixins.typography.paragraph(3, false)}
    }
  `}
` as FlattenSimpleInterpolation

export default inputElementSuggestions
