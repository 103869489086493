/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { Icon } from '@vfuk/core-icon/dist/styles/Icon.style'

import { StyledIconButtonProps } from './IconButton.style.types'

import localThemes from '../themes/IconButton.theme'

export const IconButton = styled(Interaction)((props: StyledIconButtonProps): FlattenSimpleInterpolation => {
const localTheme = localThemes(props.theme)

return css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  height: ${localTheme.size[props.size!].sm.buttonSize}px;
  width: ${localTheme.size[props.size!].sm.buttonSize}px;
  border-radius: ${props.theme.border.radius[2]};
  font-weight: ${props.theme.fontWeight[2]};
  background: ${localTheme.appearance[props.appearance!].backgroundColor};
  border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].border};

  ${props.theme.mixins.respondTo.md(css`
    height: ${localTheme.size[props.size!].md.buttonSize}px;
    width: ${localTheme.size[props.size!].md.buttonSize}px;
  `)}

  ${Icon} {
    * {
      ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].iconColor)};
    }
  }

  ${props.state !== 'disabled' && css`
    &:hover {
      background: ${localTheme.appearance[props.appearance!].hover.backgroundColor};
      border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].hover.border};

      ${Icon} {
        * {
          ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].hover.iconColor)};
        }
      }
    }  
  `}

  ${props.state === 'disabled' && css`
    cursor: default;
  `}

  ${props.shape === 'circle' && css`
    border-radius: 50%;
  `}

  ${props.state && css`
    background: ${localTheme.state[props.state!].backgroundColor};
    border: none;

    ${Icon} {
      * {
        ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].iconColor)};
      }
    }

    ${props.state !== 'disabled' && css`
      &:hover {
        background: ${localTheme.state[props.state!].hover.backgroundColor};

        ${Icon} {
          * {
            ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].hover.iconColor)};
          }
        }
      }    
    `}
  `}

  ${props.inverse && css`
    background: ${localTheme.appearance[props.appearance!].inverse.backgroundColor};
    border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].inverse.border};

    ${Icon} {
      * {
        ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].inverse.iconColor)};
      }
    }

    ${props.state !== 'disabled' && css`
      &:hover {
        background: ${localTheme.appearance[props.appearance!].inverse.hover.backgroundColor};
        border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${localTheme.appearance[props.appearance!].inverse.hover.border};

        ${Icon} {
          * {
          ${props.theme.mixins.iconAppearance(localTheme.appearance[props.appearance!].inverse.hover.iconColor)}
          }
        }
      }
    `}
  `}

  ${props.state && props.inverse && css`
    background: ${localTheme.state[props.state!].inverse.backgroundColor};

    ${Icon} {
      * {
         ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].inverse.iconColor)};
      }
    }

    ${props.state !== 'disabled' && css`
      &:hover {
        background: ${localTheme.state[props.state!].inverse.hover.backgroundColor};

        ${Icon} {
          * {
          ${props.theme.mixins.iconAppearance(localTheme.state[props.state!].inverse.hover.iconColor)};
          }
        }
      }
    `}
  `}
`})
