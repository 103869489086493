/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import ListGroup from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'

import { StyledFieldWrapperProps } from './HelpText.style.types'

export const HelpList = styled(ListGroup)((props: Pick<StyledFieldWrapperProps, 'theme'>): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.spacing('margin-top', 2)};
`)

export const HelpText = styled(ListItem)((props: Pick<StyledFieldWrapperProps, 'theme' | 'state'>): FlattenSimpleInterpolation => {
  return css`
    color: ${props.theme.color.monochrome5.default};
    ${props.state === 'disabled' && css`
      color: ${props.theme.color.monochrome6.disabled};
    `}
    font-size: ${props.theme.typography.paragraph[1].fontDesktop};

    [class*=Interaction] {
      color: ${props.theme.color.monochrome5.default};
      border-bottom: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome5.default};
      display: inline-block;
      width: auto;
    }
  `
})
