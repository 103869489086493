import React, { PureComponent, ReactNode } from 'react'

import { v4 as uuid } from 'uuid'
import isFunction from 'lodash/isFunction'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'

import TextInput from '@vfuk/core-text-input'

import * as Styled from './styles/RangeSlider.style'

import { RangeSliderProps, RangeSliderState } from './RangeSlider.types'

const SliderWithTooltip = createSliderWithTooltip(Slider)

type LabelProp = Pick<RangeSliderProps, 'labels'>
export default class RangeSlider extends PureComponent<RangeSliderProps, RangeSliderState> {
  public constructor(props: RangeSliderProps) {
    super(props)
    this.state = {
      textInputValue: `${props.value}`,
      id: props.id ? props.id : uuid(),
      previousValue: props.value,
    }
  }

  public static defaultProps: Partial<RangeSliderProps> = {
    prefix: '',
    suffix: '',
  }

  public static getDerivedStateFromProps(props: RangeSliderProps, state: RangeSliderState): Partial<RangeSliderState> | null {
    // Updates the textInputValue only if the value prop has changed
    if (state.previousValue === props.value) return null
    return {
      textInputValue: `${props.value}`,
      previousValue: props.value,
    }
  }

  private handleOnChange = (value: number): void => {
    if (isFunction(this.props.onChange)) {
      this.props.onChange(value)
    }
  }

  private handleOnBlur = (): void => {
    if (isFunction(this.props.onBlur)) {
      this.props.onBlur(this.props.value)
    }
  }

  private handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    const numericValue = Number(value)

    if (Number.isNaN(numericValue)) return

    this.setState({
      textInputValue: value,
    })

    if (numericValue < this.props.min || numericValue > this.props.max) {
      if (isFunction(this.props.onInvalidInputEntry)) {
        this.props.onInvalidInputEntry()
      }
      return
    }

    this.handleOnChange(numericValue)
  }

  private tipFormatter = (value: number): string => {
    if (this.props.labels && this.props.labels[value]) {
      return `${this.props.prefix}${this.props.labels[value]}${this.props.suffix}`
    }
    return `${this.props.prefix}${value}${this.props.suffix}`
  }

  private getFormattedLabels = (): LabelProp | undefined => {
    if (!this.props.labels) return
    const labels = { ...this.props.labels }
    Object.keys(labels).forEach((key: string): void => {
      const numericKey = Number(key)
      labels[numericKey] = `${this.props.prefix}${labels[numericKey]}${this.props.suffix}`
    })
    return labels
  }

  public render(): ReactNode {
    const formattedLabels = this.getFormattedLabels()
    const accessibleName = `${this.props.name ? this.props.name : this.props.id}`
    return (
      <Styled.RangeSlider
        className={this.props.className}
        state={this.props.state}
        onBlur={this.handleOnBlur}
        hasLabels={this.props.labels}
        hasTextInput={this.props.showTextInput}
        id={this.props.id}
        aria-label={this.props.srLabel}
        aria-describedby={this.props.describedBy}
      >
        <Choose>
          <When condition={this.props.showTextInput}>
            <Styled.SliderWithInput hasPrefix={this.props.prefix}>
              <Styled.SliderWithInputContainer>
                <Slider
                  ariaLabelForHandle={`Handle for ${accessibleName}`}
                  value={this.props.value}
                  onChange={this.handleOnChange}
                  min={this.props.min}
                  max={this.props.max}
                  step={this.props.step}
                  disabled={this.props.state === 'disabled'}
                  dots={this.props.showDots}
                  marks={formattedLabels}
                />
              </Styled.SliderWithInputContainer>
              <If condition={this.props.prefix}>
                <Styled.TextInputPrefix>{this.props.prefix}</Styled.TextInputPrefix>
              </If>
              <Styled.SrLabel htmlFor={`${accessibleName}-text-input`}>{this.props.srLabel}</Styled.SrLabel>
              <TextInput
                value={this.state.textInputValue}
                onChange={this.handleTextInputChange}
                type='number'
                id={`${accessibleName}-text-input`}
                state={this.props.state}
                describedBy={this.props.describedBy}
              />
              <If condition={this.props.suffix}>
                <Styled.TextInputSuffix>{this.props.suffix}</Styled.TextInputSuffix>
              </If>
            </Styled.SliderWithInput>
          </When>
          <Otherwise>
            <Styled.SliderWithTooltip>
              <SliderWithTooltip
                ariaLabelForHandle={`Handle for ${accessibleName}`}
                value={this.props.value}
                onChange={this.handleOnChange}
                min={this.props.min}
                max={this.props.max}
                step={this.props.step}
                disabled={this.props.state === 'disabled'}
                dots={this.props.showDots}
                marks={formattedLabels}
                tipFormatter={this.tipFormatter}
              />
            </Styled.SliderWithTooltip>
          </Otherwise>
        </Choose>
      </Styled.RangeSlider>
    )
  }
}
