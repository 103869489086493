import { Elevation } from '@vfuk/core-styles/dist/constants/elevation.types'

const elevation: Elevation = {
  size: 0,
  spread: 2,
  offset: 8,
  color: 'rgba(0,0,0,0.16)',
}

export default elevation
