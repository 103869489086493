import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'
import createMixin from '@vfuk/core-styles/dist/utils/createMixin'
import { Size, RequiredSizes, AllSizes } from '@vfuk/core-icon-generator/dist/IconGenerator.types'

import { IconSize } from '../../Icon.types'

function getIconSize(theme: Theme, group: string, size: IconSize): Size {
  const iconProperties = theme.getIconProperties(group)
  if (!size) return iconProperties.size[iconProperties.defaultSize]
  return iconProperties.size[size as unknown as keyof (RequiredSizes | AllSizes)] || iconProperties.size[iconProperties.defaultSize]
}

export default createMixin((theme: Theme, size: IconSize, isResponsive: boolean, group: string): FlattenSimpleInterpolation => {
  const iconSize = getIconSize(theme, group, size)

  if (!isResponsive) {
    return css`
      width: ${iconSize.xl}px;
      height: ${iconSize.xl}px;
    `
  }

  return css`
    width: ${iconSize.sm}px;
    height: ${iconSize.sm}px;

    ${theme.mixins.respondTo.md(css`
      width: ${iconSize.md}px;
      height: ${iconSize.md}px;
    `)}

    ${theme.mixins.respondTo.lg(css`
      width: ${iconSize.lg}px;
      height: ${iconSize.lg}px;
    `)}

    ${theme.mixins.respondTo.xl(css`
      width: ${iconSize.xl}px;
      height: ${iconSize.xl}px;
    `)}
  `
})
