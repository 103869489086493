import { Breakpoints } from './breakpoints.types'

const breakpoints: Breakpoints = {
  sm: 0,
  md: 768,
  lg: 1025,
  xl: 1441,
}

export default breakpoints
