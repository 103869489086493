import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { IconTheme } from './IconWithBackground.theme.types'

const iconLocal = (theme: Theme): IconTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.primary1.default,
        inverseIcon: true,
        inverse: {
          backgroundColor: theme.inverseColor.primary1.default,
          inverseIcon: true,
        },
      },
      secondary: {
        backgroundColor: theme.color.monochrome6.default,
        inverseIcon: true,
        inverse: {
          backgroundColor: theme.inverseColor.monochrome6.default,
          inverseIcon: false,
        },
      },
      alt1: {
        backgroundColor: theme.color.monochrome1.default,
        inverseIcon: false,
        inverse: {
          backgroundColor: theme.inverseColor.monochrome1.default,
          inverseIcon: true,
        },
      },
      alt2: {
        backgroundColor: theme.color.secondary1.default,
        inverseIcon: true,
        inverse: {
          backgroundColor: theme.inverseColor.secondary1.default,
          inverseIcon: true,
        },
      },
      alt3: {
        backgroundColor: theme.color.monochrome3.default,
        inverseIcon: false,
        inverse: {
          backgroundColor: theme.inverseColor.monochrome3.default,
          inverseIcon: true,
        },
      },
    },
    size: {
      1: {
        iconSize: 2,
        sm: {
          containerSize: 32,
        },
        md: {
          containerSize: 32,
        },
      },
      2: {
        iconSize: 2,
        sm: {
          containerSize: 36,
        },
        md: {
          containerSize: 36,
        },
      },
      3: {
        iconSize: 3,
        sm: {
          containerSize: 44,
        },
        md: {
          containerSize: 44,
        },
      },
      4: {
        iconSize: 3,
        sm: {
          containerSize: 48,
        },
        md: {
          containerSize: 48,
        },
      },
      5: {
        iconSize: 4,
        sm: {
          containerSize: 56,
        },
        md: {
          containerSize: 60,
        },
      },
    },
  }
}

export default iconLocal
