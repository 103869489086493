/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { Icon } from '@vfuk/core-icon/dist/styles/Icon.style'
import { LoadingSpinner } from '@vfuk/core-loading-spinner/dist/styles/LoadingSpinner.style'

import { StyledButtonProps } from './Button.style.types'

import localThemes from '../themes/Button.theme'

export const Button = styled(Interaction)((props: StyledButtonProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: .4s all ease;
    line-height: 12px;
    width: 100%;
    height: 44px;
    min-width: 152px;

    font-weight: ${props.theme.fontWeight[2]};
    color: ${localTheme.appearance[props.appearance!].color};
    background: ${localTheme.appearance[props.appearance!].backgroundColor};
    border-radius: ${localTheme.borderRadius};
    ${props.theme.mixins.advancedSpacing('padding', [3, 5, 3, 5])};

    ${Icon},
    & > * {
      transition: .4s all ease;
    }

    ${props.theme.mixins.themedBorderGenerator({ ...localTheme.appearance[props.appearance!].border })}

    ${props.theme.mixins.respondTo.md(css`
      ${props.width === 'auto' && css`
        width: auto;
      `}
    `)}

    ${props.state && css`
      opacity: ${localTheme.appearance[props.appearance!].state[props.state].opacity};
      color: ${localTheme.appearance[props.appearance!].state[props.state].color};
      background: ${localTheme.appearance[props.appearance!].state[props.state].backgroundColor};

      ${props.theme.mixins.themedBorderGenerator({ ...localTheme.appearance[props.appearance!].state[props.state].border })}
    `}

    ${props.state === 'disabled' && css`
      cursor: default;
    `}

    ${props.state === 'selected' && css`

      ${Icon} {
        ${props.theme.mixins.spacing('margin-right', 2)}
      }
    `}
    
    ${props.state !== 'disabled' && css`
      &:hover {
        color: ${localTheme.appearance[props.appearance!].hover.color};
        background: ${localTheme.appearance[props.appearance!].hover.backgroundColor};
        border-color: ${localTheme.appearance[props.appearance!].hover.backgroundColor};

        ${LoadingSpinner} {
          svg {
            & > * {
              stroke: ${localTheme.appearance[props.appearance!].hover.color};
            }
          }
        }

        ${props.state && css`
          color: ${localTheme.appearance[props.appearance!].state[props.state].hover.color};
          background: ${localTheme.appearance[props.appearance!].state[props.state].hover.backgroundColor};
          border-color: ${localTheme.appearance[props.appearance!].state[props.state].hover.backgroundColor};

          ${LoadingSpinner} {
            svg {
              & > * {
                stroke: ${localTheme.appearance[props.appearance!].state[props.state].hover.color};
              }
            }
          }
        `}
      }
    `}

    ${props.state !== 'disabled' && css`
      &:active {
        background: ${localTheme.appearance[props.appearance!].pressed?.backgroundColor};
        border-color: ${localTheme.appearance[props.appearance!].pressed?.backgroundColor};
        color: ${localTheme.appearance[props.appearance!].pressed?.color};

        ${LoadingSpinner} {
          svg {
            & > * {
              stroke: ${localTheme.appearance[props.appearance!].pressed?.color};
            }
          }
        }

        ${props.state && css`
          background: ${localTheme.appearance[props.appearance!].state[props.state]?.pressed?.backgroundColor};
          border-color: ${localTheme.appearance[props.appearance!].state[props.state]?.pressed?.backgroundColor};
          color: ${localTheme.appearance[props.appearance!].state[props.state]?.pressed?.color};
        `}
      }
    `}

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${localTheme.focus};
    }

    ${props.inverse && css`
      color: ${localTheme.appearance[props.appearance!].inverse.color};
      background: ${localTheme.appearance[props.appearance!].inverse.backgroundColor};

      ${props.theme.mixins.themedBorderGenerator({ ...localTheme.appearance[props.appearance!].inverse.border })}

      ${props.state && css`
        opacity: ${localTheme.appearance[props.appearance!].inverse.state[props.state].opacity};
        color: ${localTheme.appearance[props.appearance!].inverse.state[props.state].color};
        background: ${localTheme.appearance[props.appearance!].inverse.state[props.state].backgroundColor};

        ${props.theme.mixins.themedBorderGenerator({ ...localTheme.appearance[props.appearance!].inverse.state[props.state].border })}
      `}

      ${props.state !== 'disabled' && css`
        &:hover {
          color: ${localTheme.appearance[props.appearance!].inverse.hover.color};
          background: ${localTheme.appearance[props.appearance!].inverse.hover.backgroundColor};
          border-color: ${localTheme.appearance[props.appearance!].inverse.hover.backgroundColor};

          ${LoadingSpinner} {
            svg {
              & > * {
                stroke: ${localTheme.appearance[props.appearance!].inverse.hover.color};
              }
            }
          }

          ${props.state && css`
            color: ${localTheme.appearance[props.appearance!].inverse.state[props.state].hover.color};
            background: ${localTheme.appearance[props.appearance!].inverse.state[props.state].hover.backgroundColor};
            border-color: ${localTheme.appearance[props.appearance!].inverse.state[props.state].hover.backgroundColor};

            ${LoadingSpinner} {
              svg {
                & > * {
                  stroke: ${localTheme.appearance[props.appearance!].inverse.state[props.state].hover.color};
                }
              }
            }
          `}
        }
      `}

      ${props.state !== 'disabled' && css`
        &:active {
          background: ${localTheme.appearance[props.appearance!].inverse.pressed?.backgroundColor};
          border-color: ${localTheme.appearance[props.appearance!].inverse.pressed?.backgroundColor};
          color: ${localTheme.appearance[props.appearance!].inverse.pressed?.color};

          ${LoadingSpinner} {
            svg {
              & > * {
                stroke: ${localTheme.appearance[props.appearance!].inverse.pressed?.color};
              }
            }
          }

          ${props.state && css`
            background: ${localTheme.appearance[props.appearance!].state[props.state]?.pressed?.backgroundColor};
            border-color: ${localTheme.appearance[props.appearance!].state[props.state]?.pressed?.backgroundColor};
            color: ${localTheme.appearance[props.appearance!].state[props.state]?.pressed?.color};
          `}
        }
      `}
    `}
  `
})

export const HiddenText = styled.span((): FlattenSimpleInterpolation => {
  return css`
    visibility: hidden;
  `
})

export const LoadingIcon = styled.div((): FlattenSimpleInterpolation => {
  return css`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
})
/* eslint-enable complexity, sonarjs/cognitive-complexity */
