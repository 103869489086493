import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Animation } from './animation.types'

import createMixin from '../utils/createMixin'

const none: Animation = {
  animation: createMixin(
    (): FlattenSimpleInterpolation => css`
      @keyframes none {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 1;
        }
      }
    `,
  ) as Animation['animation'],
  config: {
    duration: 0,
  },
}

export default none
