import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledListItemProps } from './ListItem.style.types'

export const InteractionListItem = styled(Interaction)((): FlattenSimpleInterpolation => css`
  width: 100%;
  text-align: left;
`)

export const ListItem = styled.li((props: StyledListItemProps): FlattenSimpleInterpolation => css`
  ${props.listStyle === 'none' && css`
    list-style: none;
  `}

  ${props.listType === 'unordered' && props.listStyle === 'default' && css`
    list-style: disc;
    margin-left: ${props.theme.spacing.fixed[6]}px;
  `}

  ${props.listType === 'ordered' && props.listStyle === 'default' && css`
    list-style: decimal;
    margin-left: ${props.theme.spacing.fixed[6]}px;
    padding-left: ${props.theme.spacing.fixed[2]}px;
  `}
`)
