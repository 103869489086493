/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import ListItem from '@vfuk/core-list-item'

import { StyledStateTextProps } from './StateText.style.types'

import localThemes from '../../../themes/FieldWrapper.theme'

export const StateText = styled(ListItem)((props: Pick<StyledStateTextProps, 'theme' | 'state'>): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    ${props.state === 'error' && css`
      color: ${localTheme.state.error.color};
    `}
    font-size: ${props.theme.typography.paragraph[1].fontDesktop};

    [class*=Interaction] {
      ${props.state === 'error' && css`
        color: ${localTheme.state.error.color};
      `}
      border-bottom: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome5.default};
      display: inline-block;
      width: auto;
    }
  `
})

export const StateTextContainer = styled.div((props: Pick<StyledStateTextProps, 'theme' | 'state' | 'multipleStateText'>): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    display: flex;
    ${!props.multipleStateText && css`
      align-items: center;
    `}
    font-size: ${props.theme.typography.paragraph[1].fontDesktop};
    ${props.theme.mixins.spacing('margin-top', 2)};

    svg {
      ${props.theme.mixins.spacing('margin-right', 1)};
      ${props.multipleStateText && css`
        margin-top: 5px;
      `}
    }

    ${props.state === 'error' && css`
      color: ${localTheme.state.error.color};
    `}
  `
})
