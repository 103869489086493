import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { LinkWithIconTheme } from './LinkWithIcon.theme.types'

const linkWithIconLocal = (theme: Theme): LinkWithIconTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome5.default,
        iconColor: 'secondary',
        inverse: {
          color: theme.inverseColor.monochrome5.default,
          iconColor: 'primary',
          inverseIcon: true,
        },
      },
      secondary: {
        color: theme.color.monochrome5.default,
        iconColor: 'primary',
        inverse: {
          color: theme.inverseColor.monochrome5.default,
          iconColor: 'primary',
          inverseIcon: true,
        },
      },
    },
    state: {
      selected: {
        color: theme.color.primary1.default,
        iconColor: 'secondary',
        inverse: {
          color: theme.inverseColor.primary1.default,
          iconColor: 'primary',
          inverseIcon: true,
        },
      },
      disabled: {
        color: theme.color.monochrome4.default,
        iconColor: theme.color.monochrome4.default,
        inverse: {
          color: theme.inverseColor.monochrome4.default,
          iconColor: theme.color.monochrome4.default,
        },
      },
    },
  }
}

export default linkWithIconLocal
