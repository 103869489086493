import React, { ReactElement, FC } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'

import localThemes from './themes/IconWithBackground.theme'

import * as Styled from './styles/IconWithBackground.style'
import { IconWithBackgroundProps } from './IconWithBackground.types'

export const IconWithBackground: FC<IconWithBackgroundProps> = ({
  icon,
  appearance = 'primary',
  size = 5,
  shape = 'circle',
  theme,
  inverse = false,
}: IconWithBackgroundProps): ReactElement => {
  const localTheme = localThemes(theme!)

  return (
    <Styled.IconWithBackground inverse={inverse} appearance={appearance} size={size} shape={shape}>
      <Icon
        name={icon.name}
        appearance='primary'
        size={localTheme.size[size!].iconSize}
        inverse={inverse ? localTheme.appearance[appearance].inverse.inverseIcon : localTheme.appearance[appearance].inverseIcon}
      />
    </Styled.IconWithBackground>
  )
}

export default withTheme(IconWithBackground)
