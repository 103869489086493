import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { CollapsibleContainerTheme } from './CollapsibleContainer.theme.types'

const collapsibleContainerLocal = (theme: Theme): CollapsibleContainerTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome6.default,
        iconAppearance: 'secondary',
        inverseIcon: false,
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          iconAppearance: 'primary',
          inverseIcon: true,
        },
      },
      secondary: {
        color: theme.color.monochrome6.default,
        iconAppearance: 'primary',
        inverseIcon: false,
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          iconAppearance: 'primary',
          inverseIcon: true,
        },
      },
    },
  }
}

export default collapsibleContainerLocal
