import React, { PureComponent, ReactNode } from 'react'

import Icon from '@vfuk/core-icon'

import { OverflowMenu, OverflowMenuItem } from '@vfuk/core-overflow-menu'

import SelectInputContent from './components/SelectInputContent'
import * as Styled from './styles/SelectInputWithMenu.style'

import { SelectInputWithMenuProps, SelectInputWithMenuState, Option } from './SelectInputWithMenu.types'

class SelectInputWithMenu extends PureComponent<SelectInputWithMenuProps, SelectInputWithMenuState> {
  public state: SelectInputWithMenuState = {
    isOpen: false,
  }

  private buttonRef = React.createRef<HTMLButtonElement>()

  componentDidMount(): void {
    if (this.buttonRef.current && this.hasMultipleOptions) {
      this.buttonRef.current.addEventListener('blur', this.handleOnBlur)
      this.buttonRef.current.addEventListener('keydown', this.handleKeyPress)
    }
  }

  componentWillUnmount(): void {
    if (this.buttonRef.current && this.hasMultipleOptions) {
      this.buttonRef.current.removeEventListener('keydown', this.handleKeyPress)
      this.buttonRef.current.removeEventListener('blur', this.handleOnBlur)
    }
  }

  private get hasMultipleOptions(): boolean {
    return this.props.options.length > 1
  }

  private get fieldName(): string {
    return this.props.name ? this.props.name : this.props.id
  }

  private get selectedOption(): Option | undefined {
    return this.props.options.find((option) => option.value === this.props.value)
  }

  private handleKeyPress = (event: KeyboardEvent): void => {
    const openMenu = event.code === 'ArrowUp' || event.code === 'ArrowDown'

    if (openMenu) {
      this.setState({ isOpen: true })
    }
  }

  private handleToggleMenu = (): void => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }

  private handleCloseMenu = (): void => {
    this.setState({ isOpen: false })
  }

  private handleOnChange = (newValue: string): void => {
    if (this.props.onChange) {
      this.props.onChange(newValue, this.fieldName)
    }
  }

  private handleOnBlur = (): void => {
    if (!this.state.isOpen && this.props.onBlur) this.props.onBlur(this.props.value, this.fieldName)
  }

  private handleOutsideClick = (): void => {
    this.handleCloseMenu()
    this.handleOnBlur()
  }

  public render(): ReactNode {
    return (
      <Choose>
        <When condition={!this.hasMultipleOptions}>
          <Styled.SingleOptionContainer>
            <If condition={this.selectedOption}>
              <SelectInputContent text={this.selectedOption!.text}>{this.selectedOption?.children}</SelectInputContent>
            </If>
            <If condition={!this.selectedOption && this.props.placeholder}>
              <SelectInputContent text={this.props.placeholder!}>{this.props?.children}</SelectInputContent>
            </If>
          </Styled.SingleOptionContainer>
        </When>
        <Otherwise>
          <Styled.SelectInputWithMenuWrapper>
            <Styled.SelectInputWithMenu
              state={this.props.state}
              disabled={this.props.state === 'disabled'}
              id={this.props.id}
              onInteraction={this.handleToggleMenu}
              domRef={this.buttonRef}
              dataAttributes={
                this.selectedOption?.srText
                  ? { ...this.props.dataAttributes, 'aria-label': this.selectedOption!.srText }
                  : this.props.dataAttributes
              }
              aria-disabled={this.props.state === 'disabled'}
              aria-invalid={this.props.state === 'error'}
              aria-describedby={this.props.describedBy}
            >
              <If condition={this.selectedOption}>
                <SelectInputContent text={this.selectedOption!.text}>{this.selectedOption?.children}</SelectInputContent>
              </If>
              <If condition={!this.selectedOption && this.props.placeholder}>
                <SelectInputContent text={this.props.placeholder!}>{this.props?.children}</SelectInputContent>
              </If>
            </Styled.SelectInputWithMenu>
            <Styled.IconsWrapper>
              <Styled.IconContainer state={this.props.state} open={this.state.isOpen}>
                <Icon name='chevron-down' group='system' size={1} />
              </Styled.IconContainer>
            </Styled.IconsWrapper>
            <OverflowMenu
              activeItem={this.props.value}
              isOpen={this.state.isOpen}
              triggerRef={this.buttonRef}
              onChange={this.handleOnChange}
              onClose={this.handleCloseMenu}
              onOutsideClick={this.handleOutsideClick}
            >
              {this.props.options.map((option) => (
                <OverflowMenuItem key={option.value} {...option} />
              ))}
            </OverflowMenu>
          </Styled.SelectInputWithMenuWrapper>
        </Otherwise>
      </Choose>
    )
  }
}

export default SelectInputWithMenu
