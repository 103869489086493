import React, { FC, Fragment, memo } from 'react'

import IconWithBackground from '@vfuk/core-icon-with-background'

import Span from '@vfuk/core-span'

import * as Styled from './styles/ProgressBar.style'

import { ProgressBarProps } from './ProgressBar.types'

const ProgressBar: FC<ProgressBarProps> = ({ value, topText, icon, appearance = 'primary', inverse = false }) => {
  return (
    <Fragment>
      <Styled.ProgressBarContainer inverse={inverse} topText={topText}>
        <If condition={icon?.name}>
          <Styled.IconWithBackgroundWrapper>
            <IconWithBackground icon={icon} shape='square' size='2' appearance='alt3' />
          </Styled.IconWithBackgroundWrapper>
        </If>

        <Styled.ProgressBarSection>
          <Styled.TopText>
            <Span inverse={inverse}>{topText?.left}</Span>
            <Span inverse={inverse}>{topText?.right}</Span>
          </Styled.TopText>

          <Styled.ProgressBarTrack>
            <Styled.ProgressBar
              role='progressbar'
              inverse={inverse}
              width={value}
              appearance={appearance}
              aria-valuenow={value}
              aria-valuemin={0}
              aria-valuemax={100}
            ></Styled.ProgressBar>
          </Styled.ProgressBarTrack>
        </Styled.ProgressBarSection>
      </Styled.ProgressBarContainer>
    </Fragment>
  )
}

export default memo(ProgressBar)
