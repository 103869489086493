/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledPillProps } from './Pill.style.types'

import localThemes from '../themes/Pill.theme'

export const Pill = styled.span<StyledPillProps>((props: StyledPillProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    padding: ${props.theme.spacing.fixed[1]}px ${props.theme.spacing.fixed[3]}px;
    background: ${localTheme.appearance[props.appearance!].background};
    color: ${localTheme.appearance[props.appearance!].color};
    border-radius: ${props.theme.border.radius[3]};
    ${props.theme.mixins.typography.paragraph(1, false)};
    font-weight: ${props.theme.fontWeight[3]};

    ${props.state && css`
      background: ${localTheme.state[props.state].background};
      color: ${localTheme.state[props.state].color};
    `}
  `
})
