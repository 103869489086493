import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Col } from 'styled-bootstrap-grid'

import { StyledGridColumn } from './GridColumn.style.types'

export const GridColumn = styled(Col)((props: StyledGridColumn): FlattenSimpleInterpolation => css`
  ${props.autoMargin && `
    margin-${props.autoMargin}: auto;
  `}

  ${props.marginTop && props.theme.mixins.spacing('margin-top', props.marginTop)}

  ${props.marginBottom && props.theme.mixins.spacing('margin-bottom', props.marginBottom)}
`)
