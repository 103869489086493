import { css, FlattenSimpleInterpolation } from 'styled-components'

import { MixinRespondToGeneric } from './respondTo.types'

import { Theme } from '../themes/themes.types'
import createMixin from '../utils/createMixin'

function getMdBreakpoint(theme: Theme, styles: FlattenSimpleInterpolation): FlattenSimpleInterpolation {
  const styleguideBreakpoint = (): FlattenSimpleInterpolation => {
    if (typeof window === 'undefined' || !window.sgBreakpoint) {
      return css`
        @media (min-width: ${theme.breakpoints.md}px) {
          ${styles}
        }
      `
    }
    if (typeof window !== 'undefined' && (window.sgBreakpoint === 'md' || window.sgBreakpoint === 'lg' || window.sgBreakpoint === 'xl')) {
      return css`
        ${styles}
      `
    }
    return css``
  }
  return styleguideBreakpoint()
}

function getLgBreakpoint(theme: Theme, styles: FlattenSimpleInterpolation): FlattenSimpleInterpolation {
  const styleguideBreakpoint = (): FlattenSimpleInterpolation => {
    if (typeof window === 'undefined' || !window.sgBreakpoint) {
      return css`
        @media (min-width: ${theme.breakpoints.lg}px) {
          ${styles}
        }
      `
    }
    if (typeof window !== 'undefined' && (window.sgBreakpoint === 'lg' || window.sgBreakpoint === 'xl')) {
      return css`
        ${styles}
      `
    }
    return css``
  }
  return styleguideBreakpoint()
}

function getXlBreakpoint(theme: Theme, styles: FlattenSimpleInterpolation): FlattenSimpleInterpolation {
  const styleguideBreakpoint = (): FlattenSimpleInterpolation => {
    if (typeof window === 'undefined' || !window.sgBreakpoint) {
      return css`
        @media (min-width: ${theme.breakpoints.xl}px) {
          ${styles}
        }
      `
    }
    if (typeof window !== 'undefined' && window.sgBreakpoint === 'xl') {
      return css`
        ${styles}
      `
    }
    return css``
  }
  return styleguideBreakpoint()
}

const md = createMixin((theme: Theme, styles: FlattenSimpleInterpolation): FlattenSimpleInterpolation => {
  return getMdBreakpoint(theme, styles)
}) as MixinRespondToGeneric

const lg = createMixin((theme: Theme, styles: FlattenSimpleInterpolation): FlattenSimpleInterpolation => {
  return getLgBreakpoint(theme, styles)
}) as MixinRespondToGeneric

const xl = createMixin((theme: Theme, styles: FlattenSimpleInterpolation): FlattenSimpleInterpolation => {
  return getXlBreakpoint(theme, styles)
}) as MixinRespondToGeneric

export default { md, lg, xl }
