import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledSelectInputContentProps } from './SelectInputContent.style.types'

export const TextContainer = styled.div((props: StyledSelectInputContentProps): FlattenSimpleInterpolation => css`
  overflow: hidden;
  display: flex;
  align-items: center;
  ${props.theme.mixins.spacing('margin-right', 2)};
`)

export const Text = styled.span((props: StyledSelectInputContentProps): FlattenSimpleInterpolation => css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${props.theme.mixins.typography.heading(1, false)};
`)

export const ChildrenContainer = styled.span((props: StyledSelectInputContentProps): FlattenSimpleInterpolation => css`
 ${props.theme.mixins.spacing('margin-right', 2)};
  display: inline-flex;
  align-items: center;
`)
