import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { LoaderTheme } from './Loader.theme.types'

const loaderLocal = (theme: Theme): LoaderTheme => {
  const animationPath = theme.getAssetLocation('animations')

  return {
    size: {
      1: {
        width: 76,
        height: 76,
        headingSize: 1,
      },
      2: {
        width: 148,
        height: 148,
        headingSize: 2,
      },
      3: {
        width: 300,
        height: 300,
        headingSize: 4,
      },
    },

    appearance: {
      1: {
        primary: {
          asset: `${animationPath}level_1_primary.json`,
          inverse: {
            asset: `${animationPath}level_1_inverse.json`,
          },
        },
        secondary: {
          asset: `${animationPath}level_1_secondary.json`,
          inverse: {
            asset: `${animationPath}level_1_secondary_inverse.json`,
          },
        },
      },
      2: {
        primary: {
          asset: `${animationPath}level_2_primary.json`,
          inverse: {
            asset: `${animationPath}level_2_inverse.json`,
          },
        },
        secondary: {
          asset: `${animationPath}level_2_secondary.json`,
          inverse: {
            asset: `${animationPath}level_2_secondary_inverse.json`,
          },
        },
      },
      3: {
        primary: {
          asset: `${animationPath}level_3_primary.json`,
          inverse: {
            asset: `${animationPath}level_3_inverse.json`,
          },
        },
        secondary: {
          asset: `${animationPath}level_3_secondary.json`,
          inverse: {
            asset: `${animationPath}level_3_secondary_inverse.json`,
          },
        },
      },
    },
  }
}

export default loaderLocal
