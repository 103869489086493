import React, { PureComponent, ReactNode } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/LinkWithIcon.style'

import { LinkWithIconProps } from './LinkWithIcon.types'
import { Appearance } from './themes/LinkWithIcon.theme.types'

import localThemes from './themes/LinkWithIcon.theme'

export class LinkWithIcon extends PureComponent<LinkWithIconProps> {
  public static defaultProps: Partial<LinkWithIconProps> = {
    appearance: 'primary',
    htmlAttributes: {},
    size: 1,
    inverse: false,
    fullSize: false,
  }

  private getDisabledIconAppearance(): Appearance['iconColor'] {
    const localTheme = localThemes(this.props.theme!)

    if (this.props.to || this.props.href) {
      return this.props.inverse
        ? localTheme.appearance[this.props.appearance!].inverse.iconColor
        : localTheme.appearance[this.props.appearance!].iconColor
    }

    return 'primary'
  }

  private getIconAppearance(): Appearance['iconColor'] {
    const localTheme = localThemes(this.props.theme!)

    if (this.props.state === 'disabled') return this.getDisabledIconAppearance()

    if (this.props.inverse) {
      return this.props.state
        ? localTheme.state[this.props.state].inverse.iconColor
        : localTheme.appearance[this.props.appearance!].inverse.iconColor
    }

    return this.props.state ? localTheme.state[this.props.state].iconColor : localTheme.appearance[this.props.appearance!].iconColor
  }

  public render(): ReactNode {
    const localTheme = localThemes(this.props.theme!)

    return (
      <Styled.LinkWithIcon
        appearance={this.props.appearance!}
        state={this.props.state}
        isLink={!!(this.props.to || this.props.href)}
        disabled={this.props.state === 'disabled'}
        inverse={this.props.inverse!}
        href={this.props.href}
        onInteraction={this.props.onClick}
        target={this.props.htmlAttributes!.target}
        to={this.props.to}
        dataAttributes={this.props.htmlAttributes!.dataAttributes}
        id={this.props.id}
        fullSize={this.props.fullSize}
        label={this.props.srText}
      >
        <Styled.IconContainer
          state={this.props.state}
          isLink={!!(this.props.to || this.props.href)}
          inverse={this.props.inverse!}
          justify={this.props.icon.justify!}
          size={this.props.size}
        >
          <Icon
            name={this.props.icon.name}
            inverse={this.props.inverse && localTheme.appearance[this.props.appearance!].inverse.inverseIcon}
            size={this.props.size}
            appearance={this.getIconAppearance()}
            group={this.props.icon.group}
          />
        </Styled.IconContainer>
        <Styled.LinkWithIconText
          size={this.props.size}
          state={this.props.state}
          isLink={!!(this.props.to || this.props.href)}
          inverse={this.props.inverse!}
        >
          {this.props.text}
        </Styled.LinkWithIconText>
      </Styled.LinkWithIcon>
    )
  }
}

export default withTheme(LinkWithIcon)
