import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledOverflowMenuProps } from './OverflowMenu.style.types'

export const OverflowMenu = styled.ul<StyledOverflowMenuProps>((props): FlattenSimpleInterpolation => css`
  position: absolute;
  padding: 0;
  margin: 0;
  max-height: 0;
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  overflow-x: auto;
  background-color: ${props.theme.color.monochrome1.default};
  border-radius: ${props.theme.border.radius[2]};
  ${props.theme.mixins.elevation(true)};
  list-style: none;
  z-index: 1000;
  transition: all 0.4s ease-in-out;

  ${props.isOpen && css`
    max-height: 192px;

    ${props.theme.mixins.respondTo.lg(css`
      max-height: 200px;
    `)}
  `}

  ${props.theme.mixins.respondTo.md(css`
    max-width: 700px
  `)}
`)
