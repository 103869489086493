import { Colors } from '@vfuk/core-styles/dist/constants/colors.types'
import placeholder from '@vfuk/core-styles/dist/constants/colorPlaceholder'

// opacity for disabled colors
const opacity = 0.38

const inverseColors: Colors = {
  // primary
  primary1: {
    default: '#EC2F2F',
    hover: '#C92828',
    pressed: '#A52121',
    disabled: `rgba(236, 47, 47, ${opacity})`,
  },
  primary2: {
    default: '#FFFFFF',
    hover: '#FFFFFF',
    pressed: '#FFFFFF',
    disabled: `rgba(255, 255, 255, ${opacity})`,
  },
  primary3: {
    default: '#4A4D4E',
    hover: '#4A4D4E',
    pressed: '#4A4D4E',
    disabled: `rgba(74, 77, 78, ${opacity})`,
  },
  primary4: {
    default: '#25282B',
    hover: '#25282B',
    pressed: '#25282B',
    disabled: `rgba(37, 40, 43, ${opacity})`,
  },

  // secondary
  secondary1: {
    default: '#0096AD',
    hover: '#007F93',
    pressed: '#006979',
    disabled: `rgba(0, 150, 173, ${opacity})`,
  },
  secondary2: {
    default: '#B04A98',
    hover: '#B04A98',
    pressed: '#B04A98',
    disabled: `rgba(176, 74, 152, ${opacity})`,
  },
  secondary3: {
    default: '#EB9700',
    hover: '#EB9700',
    pressed: '#EB9700',
    disabled: `rgba(235, 151, 0, ${opacity})`,
  },
  secondary4: {
    default: '#FECB00',
    hover: '#FECB00',
    pressed: '#FECB00',
    disabled: `rgba(254, 203, 0, ${opacity})`,
  },
  secondary5: {
    default: '#B7BF10',
    hover: '#B7BF10',
    pressed: '#B7BF10',
    disabled: `rgba(183, 191, 16, ${opacity})`,
  },
  secondary6: {
    default: '#D162D5',
    hover: '#D162D5',
    pressed: '#D162D5',
    disabled: `rgba(209, 98, 213, ${opacity})`,
  },
  secondary7: {
    default: '#F06666',
    hover: '#F06666',
    pressed: '#F06666',
    disabled: `rgba(240, 102, 102, ${opacity})`,
  },

  // accent
  accent1: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },
  accent2: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },
  accent3: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },
  accent4: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },
  accent5: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },

  // monochrome
  monochrome1: {
    default: '#0D0D0D',
    hover: '#313131',
    pressed: '#565656',
    disabled: `rgba(13, 13, 13, ${opacity})`,
  },
  monochrome2: {
    default: '#262626',
    hover: '#262626',
    pressed: '#262626',
    disabled: `rgba(38, 38, 38, ${opacity})`,
  },
  monochrome3: {
    default: '#7E7E7E',
    hover: '#7E7E7E',
    pressed: '#7E7E7E',
    disabled: `rgba(126, 126, 126, ${opacity})`,
  },
  monochrome4: {
    default: '#BEBEBE',
    hover: '#BEBEBE',
    pressed: '#BEBEBE',
    disabled: `rgba(190, 190, 190, ${opacity})`,
  },
  monochrome5: {
    default: '#F2F2F2',
    hover: '#F2F2F2',
    pressed: '#F2F2F2',
    disabled: `rgba(242, 242, 242, ${opacity})`,
  },
  monochrome6: {
    default: '#FFFFFF',
    hover: '#D9D9D9',
    pressed: '#B3B3B3',
    disabled: `rgba(255, 255, 255, ${opacity})`,
  },

  // transparency
  opacity1: {
    default: 'rgba(255, 255, 255, 0.15)',
    hover: 'rgba(255, 255, 255, 0.15)',
    pressed: 'rgba(255, 255, 255, 0.15)',
    disabled: 'rgba(255, 255, 255, 0.15)',
  },
  opacity2: {
    default: 'rgba(255, 255, 255, 0.30)',
    hover: 'rgba(255, 255, 255, 0.30)',
    pressed: 'rgba(255, 255, 255, 0.30)',
    disabled: 'rgba(255, 255, 255, 0.30)',
  },
  opacity3: {
    default: 'rgba(255, 255, 255, 0.75)',
    hover: 'rgba(255, 255, 255, 0.75)',
    pressed: 'rgba(255, 255, 255, 0.75)',
    disabled: 'rgba(255, 255, 255, 0.75)',
  },
  opacity4: {
    default: 'rgba(13, 13, 13, 0.15)',
    hover: 'rgba(13, 13, 13, 0.15)',
    pressed: 'rgba(13, 13, 13, 0.15)',
    disabled: 'rgba(13, 13, 13, 0.15)',
  },
  opacity5: {
    default: 'rgba(13, 13, 13, 0.30)',
    hover: 'rgba(13, 13, 13, 0.30)',
    pressed: 'rgba(13, 13, 13, 0.30)',
    disabled: 'rgba(13, 13, 13, 0.30)',
  },
  opacity6: {
    default: 'rgba(13, 13, 13, 0.75)',
    hover: 'rgba(13, 13, 13, 0.75)',
    pressed: 'rgba(13, 13, 13, 0.75)',
    disabled: 'rgba(13, 13, 13, 0.75)',
  },

  // gradients
  gradient1: {
    default: 'to right, #820000, #E60000',
    hover: 'to right, #820000, #E60000',
    pressed: 'to right, #820000, #E60000',
    disabled: 'to right, #820000, #E60000',
  },
  gradient2: {
    default: 'to right, #F4F4F4, #FAFAFA',
    hover: 'to right, #F4F4F4, #FAFAFA',
    pressed: 'to right, #F4F4F4, #FAFAFA',
    disabled: 'to right, #820000, #E60000',
  },
  gradient3: {
    default: 'to right, #222222, #25282B',
    hover: 'to right, #222222, #25282B',
    pressed: 'to right, #222222, #25282B',
    disabled: 'to right, #820000, #E60000',
  },

  // States
  success: {
    default: '#008A00',
    hover: '#007500',
    pressed: '#006100',
    disabled: '#008A00',
  },
  error: {
    default: '#F06666',
    hover: '#F06666',
    pressed: '#F06666',
    disabled: '#F06666',
  },
  info: {
    default: '#5F9BC8',
    hover: '#5F9BC8',
    pressed: '#5F9BC8',
    disabled: '#5F9BC8',
  },
  warn: {
    default: '#EB9700',
    hover: '#EB9700',
    pressed: '#EB9700',
    disabled: '#EB9700',
  },
  focus: {
    default: '#00B0CA',
    hover: '#00B0CA',
    pressed: '#00B0CA',
    disabled: '#00B0CA',
  },
  disabled: {
    default: '#999999',
    hover: '#999999',
    pressed: '#999999',
    disabled: '#999999',
  },
  incomplete: {
    default: '#262626',
    hover: '#262626',
    pressed: '#262626',
    disabled: '#262626',
  },
}

export default inverseColors
