import React, { ReactNode, PureComponent } from 'react'

import isFunction from 'lodash/isFunction'

import RangeSlider from '@vfuk/core-range-slider'
import FieldWrapper from '@vfuk/core-field-wrapper'

import * as Styled from './styles/RangeSliderWithLabel.style'

import { RangeSliderWithLabelProps, RangeSliderWithLabelState } from './RangeSliderWithLabel.types'

export default class RangeSliderWithLabel extends PureComponent<RangeSliderWithLabelProps, RangeSliderWithLabelState> {
  public state = {
    isTextInputValid: true,
  }

  private onInvalidInputEntry = (): void => {
    this.setState({
      isTextInputValid: false,
    })
  }

  private handleOnChange = (value: number): void => {
    this.setState({
      isTextInputValid: true,
    })

    if (isFunction(this.props.rangeSlider.onChange)) {
      this.props.rangeSlider.onChange(value)
    }
  }

  private buildStateText = (): string[] | undefined => {
    const invalidEntryMessage = `Please enter a value between ${this.props.rangeSlider.min} and ${this.props.rangeSlider.max}.`

    const stateText: string[] = this.state.isTextInputValid ? [] : [invalidEntryMessage]

    if (this.props.fieldWrapper.stateText) {
      if (Array.isArray(this.props.fieldWrapper.stateText)) {
        stateText.push(...this.props.fieldWrapper.stateText)
      } else {
        stateText.push(this.props.fieldWrapper.stateText)
      }
    }

    if (!stateText.length) {
      return undefined
    }

    return stateText
  }

  public render(): ReactNode {
    const state = this.state.isTextInputValid ? this.props.state : 'error'
    return (
      <Styled.RangeSliderWithLabel>
        <FieldWrapper
          stateText={this.buildStateText()}
          helpText={this.props.fieldWrapper.helpText}
          helpLink={this.props.fieldWrapper.helpLink}
          label={this.props.fieldWrapper.label}
          validateOn={this.props.fieldWrapper.validateOn}
          state={state}
          subText={this.props.fieldWrapper.subText}
          optional={this.props.fieldWrapper.optional}
          required={this.props.fieldWrapper.required}
          width='full'
          formElementId={this.props.rangeSlider.id}
        >
          <RangeSlider
            className={this.props.rangeSlider.className}
            srLabel={this.props.fieldWrapper.label}
            min={this.props.rangeSlider.min}
            max={this.props.rangeSlider.max}
            step={this.props.rangeSlider.step}
            value={this.props.rangeSlider.value}
            showDots={this.props.rangeSlider.showDots}
            labels={this.props.rangeSlider.labels}
            showTextInput={this.props.rangeSlider.showTextInput}
            onChange={this.handleOnChange}
            onBlur={this.props.rangeSlider.onBlur}
            onInvalidInputEntry={this.onInvalidInputEntry}
            id={this.props.rangeSlider.id}
            name={this.props.rangeSlider.name}
            prefix={this.props.rangeSlider.prefix}
            suffix={this.props.rangeSlider.suffix}
            state={state}
          />
        </FieldWrapper>
      </Styled.RangeSliderWithLabel>
    )
  }
}
