import React, { PureComponent, ReactNode, ReactElement, FunctionComponent } from 'react'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import last from 'lodash/last'
import findIndex from 'lodash/findIndex'
import onClickOutside from '@vfuk/core-helpers/dist/onClickOutside'

import { OverflowMenuItemProps } from './components/OverflowMenuItem/OverflowMenuItem.types'

import * as Styled from './styles/OverflowMenu.style'

import { OverflowMenuProps, OverflowMenuState, EventType } from './OverflowMenu.types'

import scrollElementIntoView from './helpers/scrollElementIntoView'
import KeyboardInterceptor from './helpers/KeyboardInterceptor'

type OverflowMenuItem = React.ReactElement<OverflowMenuItemProps>

class OverflowMenu extends PureComponent<OverflowMenuProps> {
  public static debounceTime = 500

  public state: OverflowMenuState = {
    focusedElementId: this.props.activeItem!,
  }

  private elementsListRef = React.createRef<HTMLUListElement>()

  private keyboardInterceptor = new KeyboardInterceptor()

  private search: string = ''

  private removeClickOutsideHandler: () => void

  private focusNext = (event: KeyboardEvent): void => {
    event.preventDefault()

    const childrenIds = this.filteredChildren.map((item) => {
      return item.props.value
    })

    const currentIndex = childrenIds.indexOf(this.state.focusedElementId || '')

    const nextIndex = currentIndex + 1

    if (nextIndex < childrenIds.length) {
      this.setFocus(childrenIds[nextIndex])
    }
  }

  private focusPrevious = (event: KeyboardEvent): void => {
    event.preventDefault()

    const childrenIds = this.filteredChildren.map((item) => {
      return item.props.value
    })

    const currentIndex = childrenIds.indexOf(this.state.focusedElementId || '')

    const previousIndex = currentIndex - 1

    if (previousIndex >= 0) {
      this.setFocus(childrenIds[previousIndex])
    }
  }

  private focusFirst = (event: KeyboardEvent): void => {
    event.preventDefault()

    this.setFocus(this.filteredChildren[0].props.value)
  }

  private focusLast = (event: KeyboardEvent): void => {
    event.preventDefault()

    this.setFocus(last(this.filteredChildren)?.props.value || null)
  }

  private selectFocusedElement = (event: KeyboardEvent): void => {
    const focusedElement = this.filteredChildren.find((item) => {
      return item.props.value === this.state.focusedElementId
    })

    if (!focusedElement?.props.disabled) {
      this.props.onChange(focusedElement!.props.value)
      this.handleClose()
    }
  }

  private handleClose = (): void => {
    const { onClose, triggerRef } = this.props

    onClose()
    if (triggerRef?.current) {
      triggerRef!.current!.focus()
    }
  }

  private handleOutsideClick = (event: EventType): void => {
    if (
      this.props.isOpen &&
      this.props.triggerRef &&
      this.props.triggerRef.current !== event.target &&
      !this.props.triggerRef.current!.contains(event.target as Node)
    ) {
      this.props.onOutsideClick()
    }
  }

  private setupKeyboardInterceptor(): void {
    const handlers = {
      ArrowDown: this.focusNext,
      ArrowUp: this.focusPrevious,
      Enter: this.selectFocusedElement,
      Space: this.selectFocusedElement,
      Escape: this.handleClose,
      Home: this.focusFirst,
      End: this.focusLast,
      Tab: this.handleClose,
    }

    const throttledCb = throttle(this.handleSearch, OverflowMenu.debounceTime)
    const clean = debounce(() => {
      this.search = ''
    }, 1000)

    const fallbackHandler = (event: KeyboardEvent): void => {
      if (event.key.length === 1 && event.key.match(/[a-z]/i)) {
        event.preventDefault()
        this.search += event.key

        throttledCb(this.search)
        clean()
      }
    }

    this.keyboardInterceptor.setup(this.elementsListRef.current!, handlers, fallbackHandler)
  }

  componentDidMount(): void {
    this.removeClickOutsideHandler = onClickOutside(this.elementsListRef, this.handleOutsideClick)
    if (this.props.isOpen) {
      this.elementsListRef.current!.focus()
      if (this.state.focusedElementId) {
        this.setFocus(this.state.focusedElementId)
      }
    }

    this.setupKeyboardInterceptor()
  }

  componentDidUpdate(prevProps: OverflowMenuProps): void {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.elementsListRef.current!.focus()
      if (this.state.focusedElementId) {
        this.setFocus(this.state.focusedElementId)
      }
    }
  }

  componentWillUnmount(): void {
    this.keyboardInterceptor.destroy()
    this.removeClickOutsideHandler()
  }

  public get filteredChildren(): OverflowMenuItem[] {
    const filteredChildren: ReactNode[] = []
    React.Children.forEach(this.props.children, (child: ReactElement) => {
      const component = child?.type as FunctionComponent
      const displayName = component?.displayName
      if (displayName && displayName.includes('OverflowMenuItem')) filteredChildren.push(child)
    })

    return filteredChildren as OverflowMenuItem[]
  }

  private handleSearch = (searchCharacter: string): void => {
    const indexOfFocused = this.state.focusedElementId
      ? findIndex(this.filteredChildren, (item) => {
          return item.props.value === this.state.focusedElementId
        })
      : 0

    const temp = [...this.filteredChildren]
    const front = temp.splice(indexOfFocused + 1)

    const childrenReordered = [...front, ...temp]

    const itemToFocus = childrenReordered.find((item) => {
      return item.props.text.toLowerCase().startsWith(searchCharacter.toLowerCase())
    })

    if (itemToFocus) {
      this.setFocus(itemToFocus.props.value)
    }
  }

  private setFocus = (focusId: string | null): void => {
    this.setState({ focusedElementId: focusId })

    scrollElementIntoView(this.elementsListRef, focusId)
  }

  public render(): ReactNode {
    return (
      <Styled.OverflowMenu
        role='listbox'
        tabIndex={-1}
        isOpen={this.props.isOpen}
        ref={this.elementsListRef}
        aria-activedescendant={this.state.focusedElementId as string}
      >
        <If condition={this.props.isOpen}>
          {this.filteredChildren.map((child: OverflowMenuItem) => {
            const { value, disabled } = child.props
            return React.cloneElement(child, {
              key: value,
              active: this.props.activeItem === value,
              focused: this.state.focusedElementId === value,
              onClick: () => {
                if (!disabled) {
                  this.props.onChange(value)
                  this.handleClose()
                }
              },
            })
          })}
        </If>
      </Styled.OverflowMenu>
    )
  }
}

export default OverflowMenu
