import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import ListItem from '@vfuk/core-list-item'
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'
import mixinIconAppearance from '@vfuk/core-styles/dist/mixins/iconAppearance'

import { StyledOverflowMenuItemProps, StyledDisabledTextContainerProp, StyledIconPlaceholderProps } from './OverflowMenuItem.style.types'

const getStylesForActiveElement = (theme: Theme):FlattenSimpleInterpolation => {

  return css`
    background-color: ${theme.color.monochrome2.default};

    [class*=Paragraph] {
      font-weight: ${theme.fontWeight[3]};
    }
  `
}

export const OverflowMenuItem = styled(ListItem)<StyledOverflowMenuItemProps>((props): FlattenSimpleInterpolation => css`
  width: 100%;
  background-color: ${props.theme.color.monochrome1.default};
  transition: .4s all ease;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    ${props.theme.mixins.spacing('padding', 3)};

    &:focus {
      box-shadow: unset;
    }
  }

  [class*=Divider] {
    position: absolute;
    bottom: 0;
    width: calc(100% - ${props.theme.spacing.responsive[6].sm}px);

    ${props.theme.mixins.respondTo.md(css`
      width: calc(100% - ${props.theme.spacing.responsive[6].md}px);
    `)}

    ${props.theme.mixins.respondTo.lg(css`
      width: calc(100% - ${props.theme.spacing.responsive[6].lg}px);
    `)}

    ${props.theme.mixins.respondTo.xl(css`
      width: calc(100% - ${props.theme.spacing.responsive[6].xl}px);
    `)}
  }

  &:last-of-type [class*=Divider] {
    display: none;
  }

  &:hover {
    ${getStylesForActiveElement(props.theme)}
  }

  ${props.focused && css`
    ${getStylesForActiveElement(props.theme)}

    &::before {
      outline: none;
      content: '';
      z-index: 1;
      border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.focus.default};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  `}

  ${props.active && css`
    ${getStylesForActiveElement(props.theme)};
  `}

  ${props.disabled && css`
    color: ${props.theme.color.disabled.default};
    background-color: ${props.theme.color.monochrome1.default};

    [class*=Paragraph] {
      font-weight: ${props.theme.fontWeight[2]};
    }

    &:hover {
      background-color: ${props.theme.color.monochrome1.default};

      button {
        cursor: not-allowed;
      }

      [class*=Paragraph] {
        font-weight: ${props.theme.fontWeight[2]};
      }
    }
  `}
`)

export const OverflowMenuItemInnerWrapper = styled.div((): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;

  p {
    color: inherit;
  }
`)

export const IconPlaceholder = styled.span<StyledIconPlaceholderProps>((props): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.spacing('margin-right', 2)};
  display: inline-flex;
  align-items: center;

  ${props.isIcon && props.disabled && css`
    [class*=Icon] {
      ${mixinIconAppearance(props.theme.color.disabled.default, props.group)}
    }
  `}
`)

export const DisabledTextContainer = styled.div<StyledDisabledTextContainerProp>((props): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.spacing('padding-top', 1)};
  display: flex;

  & > p {
    color: ${props.theme.color.error.default};
  }
`)

type HiddenIconPlaceholderProps = Pick<StyledIconPlaceholderProps, 'theme'>

export const HiddenIconPlaceholder = styled.span<HiddenIconPlaceholderProps>((props): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.spacing('margin-right', 2)};
  visibility: hidden;
  height: 0
`)
