import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Row } from 'styled-bootstrap-grid'

import { StyledGridRow } from './GridRow.style.types'

// fixes issue where styled-bootstrap-grid applies flex-basis:0 which takes precedence over min-height
// causing items overlapping each other when direction is set to column or column-reverse
// below fn resets flex-basis to default value
const flexBasis = (direction: string): string | undefined => {
  if (direction === 'column' || direction === 'column-reverse') {
    return `
      > [class*='GridColumnstyle__GridColumn'] {
          flex-basis: auto;
      }
    `
  }
}


export const GridRow = styled(Row)((props: StyledGridRow): FlattenSimpleInterpolation => css`
  ${props.noGutters && `
    margin-right: 0;
    margin-left: 0;
      > [class*='GridColumnstyle__GridColumn'] {
        padding-right: 0;
        padding-left: 0;
      }
  `}

  ${props.marginTop && props.theme.mixins.spacing('margin-top', props.marginTop)}

  ${props.marginBottom && props.theme.mixins.spacing('margin-bottom', props.marginBottom)}

  ${props.direction && `
    flex-direction: ${props.direction};
    ${flexBasis(props.direction)}
  `}

  ${props.directionMd && (
    props.theme.mixins.respondTo.md(css`
      flex-direction: ${props.directionMd};
      ${flexBasis(props.directionMd)}
    `)
  )}

  ${props.directionLg && (
    props.theme.mixins.respondTo.lg(css`
      flex-direction: ${props.directionLg};
      ${flexBasis(props.directionLg)}
    `)
  )}

  ${props.directionXl && (
    props.theme.mixins.respondTo.xl(css`
      flex-direction: ${props.directionXl};
      ${flexBasis(props.directionXl)}
    `)
  )}

  ${props.disableFlex && 'display: block;'}
`)
