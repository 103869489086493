/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

// eslint-disable-next-line
import { StyledCollapsibleContainerHeaderProps, StyledCollapsibleContainerHeaderChildrenProps, StyledCollapsibleContainerHeaderIconContainerProps } from './CollapsibleContainerHeader.style.types'

import localThemes from '../../../themes/CollapsibleContainer.theme'

export const CollapsibleContainerHeader = styled(Interaction)((props: StyledCollapsibleContainerHeaderProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    width: ${props.width === 'auto' ? 'auto' :  '100%'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${localTheme.appearance[props.appearance!].color};
    text-align: inherit;
    position: relative;

    ${props.size === 2 && css`
      font-size: ${props.theme.typography.heading[1].fontDesktop};
      font-weight: ${props.theme.fontWeight[3]};
    `}

    ${props.isInverse && css`
      color: ${localTheme.appearance[props.appearance!].inverse.color};
    `}
  `
})

// eslint-disable-next-line
export const CollapsibleContainerHeaderChildren = styled.div((props: StyledCollapsibleContainerHeaderChildrenProps): FlattenSimpleInterpolation => css`
  margin-right: ${props.theme.spacing.fixed[3]}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`)

export const IconContainer = styled.div((props: StyledCollapsibleContainerHeaderIconContainerProps): FlattenSimpleInterpolation => css`
  transition: 0.2s ease-out;
  min-height: 26px;
  display: flex;
  margin-left: auto;

  ${props.isActive && css`
    transform: rotate(180deg);
  `}

  span {
    align-items: center;
  }
`)
