/* eslint-disable max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledLinkProps } from './Link.style.types'

import localThemes from '../themes/Link.theme'

export const Link = styled(Interaction)((props: StyledLinkProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    color: ${localTheme.appearance[props.appearance!].color};

    ${props.showUnderline && `
      text-decoration: underline;
    `}

    ${props.inverse && `
      color: ${localTheme.appearance[props.appearance!].inverse.color};
    `}
  `
})
