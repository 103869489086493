import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import {LinkWithIcon} from '@vfuk/core-link-with-icon/dist/styles/LinkWithIcon.style'

import { StyledSelectInputWithLabelProps } from './SelectInputWithLabel.style.types'

export const Container = styled.div((props: StyledSelectInputWithLabelProps): FlattenSimpleInterpolation => css`
  display: flex;
  flex-direction: column;

  ${LinkWithIcon} {
    ${props.theme.mixins.spacing('margin-top', 2)}
  }
`)
