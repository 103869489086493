import React, { PureComponent, ReactNode, cloneElement } from 'react'

import InteractiveIcon from '@vfuk/core-interactive-icon'

import * as Styled from './styles/FieldWrapper.style'

import { FieldWrapperProps, ChildrenAdditionalProps, FieldWrapperState } from './FieldWrapper.types'

import HelpText from './components/HelpText'
import StateText from './components/StateText'

import { SUB_TEXT } from './constants'

import { getAriaDescribedByIds } from './utils/getDescribedByIds'

export default class FieldWrapper extends PureComponent<FieldWrapperProps> {
  public static defaultProps: Partial<FieldWrapperProps> = {
    showLabel: true,
    width: 'default',
    renderAsLabel: true,
  }

  public state: FieldWrapperState = {
    isMasked: true,
  }

  private showStateText(): boolean {
    if (this.props.stateText && this.props.state !== 'disabled') {
      return !!this.props.state
    }
    return false
  }

  private handleInputVisibilityClick = (): void => {
    this.setState({
      isMasked: !this.state.isMasked,
    })
  }

  private get childrenAdditionalProps(): ChildrenAdditionalProps {
    const additionalProps: ChildrenAdditionalProps = {}

    if (this.props.required) {
      additionalProps.required = true
    }

    if (this.props.children?.props.type === 'password' && !this.state.isMasked) {
      additionalProps.type = 'text'
    }

    const ariaDescribedByIds = getAriaDescribedByIds(
      this.props.formElementId,
      this.props.helpText,
      this.props.stateText,
      this.props.subText,
    )

    if (ariaDescribedByIds.length) additionalProps.describedBy = ariaDescribedByIds

    return additionalProps
  }

  public render(): ReactNode {
    /**
     * If the children has a root type of 'password'
     */
    const isTypePassword = this.props.children?.props.type === 'password'

    return (
      <Styled.FieldWrapper width={this.props.width}>
        <Styled.TopLabelWrapper showLabel={this.props.showLabel}>
          <Styled.FlexIndicatorContainer showLabel={this.props.showLabel}>
            <Styled.Label
              as={this.props.renderAsLabel ? ('label' as React.ElementType) : ('div' as React.ElementType)}
              htmlFor={this.props.renderAsLabel ? this.props.formElementId : undefined}
              isTypePassword={isTypePassword}
              state={this.props.state!}
              showLabel={this.props.showLabel}
            >
              {this.props.label}
            </Styled.Label>
            <If condition={this.props.showLabel}>
              <If condition={this.props.optional || this.props.required}>
                <Styled.OptionalOrRequiredLabel aria-hidden>{this.props.optional ? 'Optional' : 'Required'}</Styled.OptionalOrRequiredLabel>
              </If>
              <If condition={isTypePassword}>
                <InteractiveIcon
                  srText={`${this.state.isMasked ? 'Show' : 'Hide'} ${this.props.label.toLowerCase()}`}
                  name={this.state.isMasked ? 'show-password' : 'hide-password'}
                  size={2}
                  isResponsive={false}
                  onClick={this.handleInputVisibilityClick}
                />
              </If>
            </If>
          </Styled.FlexIndicatorContainer>
          <If condition={this.props.subText}>
            <Styled.SubText id={`${this.props.formElementId}-${SUB_TEXT}`} state={this.props.state}>
              {this.props.subText}
            </Styled.SubText>
          </If>
        </Styled.TopLabelWrapper>
        <Styled.InputWrapper>{cloneElement(this.props.children, this.childrenAdditionalProps)}</Styled.InputWrapper>
        <If condition={this.showStateText()}>
          <StateText
            state={this.props.state!}
            stateText={this.props.stateText!}
            formElementId={this.props.formElementId}
            validateOn={this.props.validateOn}
          />
        </If>
        <If condition={this.props.helpText || this.props.helpLink}>
          <HelpText
            state={this.props.state}
            formElementId={this.props.formElementId}
            helpText={this.props.helpText}
            helpLink={this.props.helpLink}
          />
        </If>
      </Styled.FieldWrapper>
    )
  }
}
