import { Border } from '@vfuk/core-styles/dist/constants/border.types'

const border: Border = {
  radius: {
    0: '0',
    1: '2px',
    2: '6px',
    3: '24px',
  },
  width: {
    1: '1px',
    2: '2px',
    3: '3px',
  },
  style: {
    1: 'solid',
    2: 'dashed',
    3: 'dotted',
  },
}
export default border
