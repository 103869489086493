/* eslint-disable max-len */
import { css, FlattenSimpleInterpolation } from 'styled-components'

import { MixinThemedBorderGenerator, BorderConfig } from './themedBorderGenerator.types'

import createMixin from '../utils/createMixin'
import { Theme } from '../themes/themes.types'

export default createMixin(
  (theme: Theme, { width, style, colors, position = '', prefix = '' }: BorderConfig): FlattenSimpleInterpolation => {
    const outerBorderColor = colors && colors.outerBorderColor
    if (!width || !style || !outerBorderColor) {
      console.error('Please provide a width, style and outerBorderColor value when using the borderGenerator mixin')
      return css``
    }

    if (prefix) {
      return css`
        ${prefix} {
          ${position &&
          `
          border${`-${position}`}: ${width} ${style} ${outerBorderColor};
        `}
          ${!position &&
          `
          border: ${width} ${style} ${outerBorderColor};
        `}
        }
      `
    }

    return css`
      ${position &&
      `
      border${`-${position}`}: ${width} ${style} ${outerBorderColor};
    `}
      ${!position &&
      `
      border: ${width} ${style} ${outerBorderColor};
    `}
    `
  },
) as MixinThemedBorderGenerator
