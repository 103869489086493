/* stylelint-disable property-no-vendor-prefix */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledTextInputProps } from './TextInput.style.types'

import localTheme from '../themes/TextInput.theme'

export const TextInputWrapper = styled.div((): FlattenSimpleInterpolation => css`
  position: relative;
`)

export const TextInput = styled.input<StyledTextInputProps>((props: StyledTextInputProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && localTheme(props.theme).state[props.state]

  return css`
    color: ${props.theme.color.monochrome5.default};
    transition: .4s box-shadow ease;
    border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome3.default};
    border-radius: ${props.theme.border.radius[2]};
    background-color: ${props.theme.color.monochrome1.default};
    width: 100%;

    ${props.theme.mixins.advancedSpacing('padding', [2, 3])}
    ${props.theme.mixins.typography.heading(1, false)}

    ${(props.state && stateFromTheme) && css`
      color: ${stateFromTheme.color};
      background-color: ${stateFromTheme.backgroundColor};
      border-color: ${stateFromTheme.border.color};
      border-width: ${stateFromTheme.border.width};
    `}

    ${props.state === 'disabled' && css`
      cursor: not-allowed;

      &:focus {
        caret-color: transparent;
      }
    `}

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
      -webkit-appearance: none;
      -webkit-box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
    }

    ::-ms-reveal {
      display: none;
    }
  `
})

type IconWrapperProps = Pick<StyledTextInputProps, 'theme'>
export const IconWrapper = styled.div((props: IconWrapperProps): FlattenSimpleInterpolation => css`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  ${props.theme.mixins.spacing('padding-right', 3)}
`)
