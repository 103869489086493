import { Fonts } from '@vfuk/core-styles/dist/constants/fonts.types'

const fonts: Fonts = {
  family: 'Vodafone',
  extension: 'woff',
  fontFiles: [
    {
      weight: 200,
      file: 'vodafone-light',
    },
    {
      weight: 400,
      file: 'vodafone-regular',
    },
    {
      weight: 700,
      file: 'vodafone-bold',
    },
    {
      weight: 900,
      file: 'vodafone-extra-bold',
    },
  ],
}

export default fonts
