import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledNumberBadgeProps } from './NumberBadge.style.types'

import localThemes from '../themes/NumberBadge.theme'

export const NumberBadge = styled.span<StyledNumberBadgeProps>(
  ({ theme, appearance }: StyledNumberBadgeProps): FlattenSimpleInterpolation => {
    const appearanceFromProp = localThemes(theme).appearance[appearance!]
    return css`
      height: 16px;
      padding: 0px ${theme.spacing.fixed[1] + 1}px;
      background-color: ${appearanceFromProp.background};
      color: ${appearanceFromProp.color};
      border-radius: 10px;
      text-align: center;
      ${theme.mixins.typography.paragraph(1, false)}
      font-weight: ${theme.fontWeight[3]};

      ${theme.mixins.respondTo.md(css`
        height: 20px;
        line-height: 20px;
        padding: 0px ${theme.spacing.fixed[2] - 1}px;
      `)}
    `
  },
)
