import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledLoaderProps } from './Loader.style.types'

import localThemes from '../themes/Loader.theme'

export const Loader = styled.div((): FlattenSimpleInterpolation => css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: fadeIn;
  animation-duration: 1.5s;
`)

type SpinnerProps = Pick<StyledLoaderProps, 'theme' | 'size'>
export const Spinner = styled.div((props: SpinnerProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme);
  return css`
    width: ${localTheme.size[props.size!].width}px;
    height: ${localTheme.size[props.size!].height}px;
  `
}) 

type HiddenLabelProps = Pick<StyledLoaderProps, 'theme'>
export const HiddenLabel = styled.span((props: HiddenLabelProps): FlattenSimpleInterpolation => css`
    ${props.theme.mixins.srOnly()}
`)