/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledSpacingProps } from './Spacing.style.types'

export const Spacing = styled.div((props: StyledSpacingProps): FlattenSimpleInterpolation => css`
  ${props.responsiveSizing ? css`
    ${props.spacingLevel.top && css`
      margin-top: ${props.theme.spacing.responsive[props.spacingLevel.top].sm}px;

      ${props.theme.mixins.respondTo.md(css`
        margin-top: ${props.theme.spacing.responsive[props.spacingLevel.top].md}px;
      `)}

      ${props.theme.mixins.respondTo.lg(css`
        margin-top: ${props.theme.spacing.responsive[props.spacingLevel.top].lg}px;
      `)}

      ${props.theme.mixins.respondTo.xl(css`
        margin-top: ${props.theme.spacing.responsive[props.spacingLevel.top].xl}px;
      `)}
    `}

    ${props.spacingLevel.right && css`
      margin-right: ${props.theme.spacing.responsive[props.spacingLevel.right].sm}px;

      ${props.theme.mixins.respondTo.md(css`
        margin-right: ${props.theme.spacing.responsive[props.spacingLevel.right].md}px;
      `)}

      ${props.theme.mixins.respondTo.lg(css`
        margin-right: ${props.theme.spacing.responsive[props.spacingLevel.right].lg}px;
      `)}

      ${props.theme.mixins.respondTo.xl(css`
        margin-right: ${props.theme.spacing.responsive[props.spacingLevel.right].xl}px;
      `)}
    `}

    ${props.spacingLevel.bottom && css`
      margin-bottom: ${props.theme.spacing.responsive[props.spacingLevel.bottom].sm}px;

      ${props.theme.mixins.respondTo.md(css`
        margin-bottom: ${props.theme.spacing.responsive[props.spacingLevel.bottom].md}px;
      `)}

      ${props.theme.mixins.respondTo.lg(css`
        margin-bottom: ${props.theme.spacing.responsive[props.spacingLevel.bottom].lg}px;
      `)}

      ${props.theme.mixins.respondTo.xl(css`
        margin-bottom: ${props.theme.spacing.responsive[props.spacingLevel.bottom].xl}px;
      `)}
    `}

    ${props.spacingLevel.left && css`
      margin-left: ${props.theme.spacing.responsive[props.spacingLevel.left].sm}px;

      ${props.theme.mixins.respondTo.md(css`
        margin-left: ${props.theme.spacing.responsive[props.spacingLevel.left].md}px;
      `)}

      ${props.theme.mixins.respondTo.lg(css`
        margin-left: ${props.theme.spacing.responsive[props.spacingLevel.left].lg}px;
      `)}

      ${props.theme.mixins.respondTo.xl(css`
        margin-left: ${props.theme.spacing.responsive[props.spacingLevel.left].xl}px;
      `)}
    `}
  ` : css`
    ${props.spacingLevel.top && css`
      margin-top: ${props.theme.spacing.fixed[props.spacingLevel.top]}px;
    `}

    ${props.spacingLevel.right && css`
      margin-right: ${props.theme.spacing.fixed[props.spacingLevel.right]}px;
    `}

    ${props.spacingLevel.bottom && css`
      margin-bottom: ${props.theme.spacing.fixed[props.spacingLevel.bottom]}px;
    `}

    ${props.spacingLevel.left && css`
      margin-left: ${props.theme.spacing.fixed[props.spacingLevel.left]}px;
    `}
  `}
`)
