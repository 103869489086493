import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledSelectInputWithMenuProps } from './SelectInputWithMenu.style.types'

import localTheme from '../themes/SelectInputWithMenu.theme'

export const SelectInputWithMenuWrapper = styled.div((props: StyledSelectInputWithMenuProps): FlattenSimpleInterpolation => css`
  position: relative;

  ul[class*='OverflowMenu'] {
    ${props.theme.mixins.spacing('margin-top', 2)};
  }
`)

export const SingleOptionContainer = styled.div((props: StyledSelectInputWithMenuProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;
  border-radius: ${props.theme.border.radius[2]};
  background-color: ${props.theme.color.monochrome1.default};
  min-width: 136px;
  width: 100%;
  ${props.theme.mixins.advancedSpacing('padding', [2, 8, 2, 3])};
  ${props.theme.mixins.typography.heading(1, false)};
  border: ${props.theme.border.style[1]} ${props.theme.border.width[1]} ${props.theme.color.monochrome3.default};
`)

export const SelectInputWithMenu = styled(Interaction)((props: StyledSelectInputWithMenuProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && localTheme(props.theme).state[props.state]

  return css`
    display: flex;
    align-items: center;
    border-radius: ${props.theme.border.radius[2]};
    background-color: ${props.theme.color.monochrome1.default};
    min-width: 136px;
    width: 100%;
    ${props.theme.mixins.advancedSpacing('padding', [2, 8, 2, 3])};
    ${props.theme.mixins.typography.heading(1, false)};
    border: ${props.theme.border.style[1]} ${props.theme.border.width[1]} ${props.theme.color.monochrome3.default};

    ${(props.state && stateFromTheme) && css`
      color: ${stateFromTheme.color};
      background-color: ${stateFromTheme.backgroundColor};
      border-color: ${stateFromTheme.border.color};
      border-width: ${stateFromTheme.border.width};

      ${props.state !== 'disabled' && css`
        padding-right: ${props.theme.spacing.responsive[11].md}px;

        ${props.theme.mixins.respondTo.md(css`
          padding-right: ${props.theme.spacing.responsive[11].lg}px;
        `)}
      `}

      ${props.state === 'disabled' && css`
        opacity: 1;
        cursor: not-allowed;
      `}
    `}
  `
  }
)

export const IconsWrapper = styled.div((): FlattenSimpleInterpolation => css`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  right: 0;
  pointer-events: none;
`)

export const IconContainer = styled.div((props: StyledSelectInputWithMenuProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && localTheme(props.theme).state[props.state]

  return css`
    padding-right: ${props.theme.spacing.fixed[3]}px;
    pointer-events: none;

    [class*='Icon'] {
      transition: transform 0.4s ease-out;
    }

    ${(props.state === 'disabled' && stateFromTheme) && css`
      [class*='Icon'] {
        * {
          ${props.theme.mixins.iconAppearance(stateFromTheme.color)};
        }
      }
    `}

    ${props.open && css`
      [class*='Icon'] {
        transform: rotate(180deg);
      }
    `}
  `
})
