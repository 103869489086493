import React, { FC, ReactElement } from 'react'

import * as Styled from './styles/Spacing.style'

import { SpacingProps } from './Spacing.types'

const Spacing: FC<SpacingProps> = ({ spacingLevel, responsiveSizing = true, children }: SpacingProps): ReactElement => {
  return (
    <Styled.Spacing
      spacingLevel={{
        top: spacingLevel.top,
        right: spacingLevel.right,
        bottom: spacingLevel.bottom,
        left: spacingLevel.left,
      }}
      responsiveSizing={responsiveSizing}
    >
      {children}
    </Styled.Spacing>
  )
}

export default Spacing
