import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { LinkTheme } from './Link.theme.types'

const linkLocal = (theme: Theme): LinkTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome5.default,
        inverse: {
          color: theme.color.monochrome1.default,
        },
      },
      secondary: {
        color: theme.color.monochrome4.default,
        inverse: {
          color: theme.color.monochrome1.default,
        },
      },
    },
  }
}

export default linkLocal
