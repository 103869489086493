import React, { PureComponent, ReactNode, RefObject } from 'react'

import { Link } from 'react-router-dom'

import * as Styled from './styles/Interaction.style'

import { InteractionProps, InteractionEvent, KeyboardEvent } from './Interaction.types'

/**
 * Interaction component
 * Notes:
 * - onClick for RouterLink and Link are for analytics purpose only!
 */
export default class Interaction extends PureComponent<InteractionProps> {
  public static defaultProps: Partial<InteractionProps> = {
    type: 'button',
    target: '_self',
  }

  /**
   * @param event
   */
  private onClick = (event: InteractionEvent): void => {
    if (!this.props.disabled && this.props.onInteraction) {
      this.props.onInteraction(event)
    }
  }

  /**
   * @param event
   */
  private onKeyDown = (event: KeyboardEvent): void => {
    if (!this.props.disabled && this.props.onKeyDown) {
      this.props.onKeyDown(event)
    }
  }

  public render(): ReactNode {
    return (
      <Choose>
        <When condition={this.props.to || this.props.href}>
          <Styled.Interaction
            as={this.props.to ? (Link as Link) : ('a' as React.ElementType)}
            ref={this.props.domRef as RefObject<HTMLAnchorElement>}
            isFullSize={this.props.fullSize}
            href={this.props.href}
            aria-label={this.props.label}
            target={this.props.target}
            {...this.props.dataAttributes}
            className={this.props.className}
            to={this.props.to}
            onClick={this.onClick}
            onKeyDown={this.onKeyDown}
            id={this.props.id}
            role={this.props.role}
            rel={this.props.rel}
          >
            {this.props.children}
          </Styled.Interaction>
        </When>
        <When condition={this.props.onInteraction}>
          <Styled.Button
            ref={this.props.domRef as RefObject<HTMLButtonElement>}
            isFullSize={this.props.fullSize}
            type={this.props.type}
            onClick={this.onClick}
            onKeyDown={this.onKeyDown}
            aria-label={this.props.label}
            aria-disabled={this.props.disabled}
            {...this.props.dataAttributes}
            className={this.props.className}
            id={this.props.id}
            role={this.props.role}
          >
            {this.props.children}
          </Styled.Button>
        </When>
        <When condition={this.props.disabled}>
          <Styled.Button
            ref={this.props.domRef as RefObject<HTMLButtonElement>}
            isFullSize={this.props.fullSize}
            type={this.props.type}
            aria-label={this.props.label}
            aria-disabled={this.props.disabled}
            {...this.props.dataAttributes}
            className={this.props.className}
            id={this.props.id}
            role={this.props.role}
          >
            {this.props.children}
          </Styled.Button>
        </When>
        <Otherwise>
          <Styled.BlankInteraction
            ref={this.props.domRef as RefObject<HTMLDivElement>}
            isFullSize={this.props.fullSize}
            {...this.props.dataAttributes}
            className={this.props.className}
          >
            {this.props.children}
          </Styled.BlankInteraction>
        </Otherwise>
      </Choose>
    )
  }
}
