import React, { FC } from 'react'

import * as Styled from './styles/NumberBadge.style'

import { NumberBadgeProps } from './NumberBadge.types'

const NumberBadge: FC<NumberBadgeProps> = ({ appearance = 'primary', number }: NumberBadgeProps) => (
  <Styled.NumberBadge appearance={appearance}>
    <Choose>
      <When condition={typeof Number(number) === 'number' && number > 999}>999+</When>
      <Otherwise>{Number(number)}</Otherwise>
    </Choose>
  </Styled.NumberBadge>
)

export default NumberBadge
