import { css, FlattenSimpleInterpolation } from 'styled-components'

import { Animation } from './animation.types'

import createMixin from '../utils/createMixin'

const scaleDown: Animation = {
  animation: createMixin(
    (): FlattenSimpleInterpolation => css`
      @keyframes scaleDown {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.25);
        }
      }
    `,
  ) as Animation['animation'],
  config: {
    duration: 150,
  },
}

export default scaleDown
