import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { PillTheme } from './Pill.theme.types'

const pillLocal = (theme: Theme): PillTheme => {
  return {
    appearance: {
      primary: {
        background: theme.color.success.default,
        color: theme.color.monochrome1.default,
      },
      secondary: {
        background: theme.color.secondary4.default,
        color: theme.color.monochrome6.default,
      },
    },
    state: {
      success: {
        background: theme.color.success.default,
        color: theme.color.monochrome1.default,
      },
      error: {
        background: theme.color.error.default,
        color: theme.color.monochrome1.default,
      },
      info: {
        background: theme.color.info.default,
        color: theme.color.monochrome1.default,
      },
      warn: {
        background: theme.color.warn.default,
        color: theme.color.monochrome1.default,
      },
      disabled: {
        background: theme.color.disabled.default,
        color: theme.color.monochrome1.default,
      },
      incomplete: {
        background: theme.color.incomplete.default,
        color: theme.color.monochrome1.default,
      },
    },
  }
}

export default pillLocal
