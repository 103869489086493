import React, { PureComponent, ReactNode } from 'react'

import * as Styled from './styles/Pill.style'

import { PillProps } from './Pill.types'

export default class Pill extends PureComponent<PillProps> {
  public static defaultProps: Partial<PillProps> = {
    appearance: 'primary',
  }

  public render(): ReactNode {
    return (
      <Styled.Pill appearance={this.props.appearance!} state={this.props.state}>
        {this.props.text}
      </Styled.Pill>
    )
  }
}
