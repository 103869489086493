import { Spacing } from './spacing.types'

const fixed = {
  0: 0,
  1: 4,
  2: 8,
  3: 16,
  4: 20,
  5: 24,
  6: 32,
  7: 40,
  8: 48,
  9: 56,
  10: 64,
  11: 72,
  12: 80,
}

const spacing: Spacing = {
  fixed,
  responsive: {
    0: {
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0,
    },
    1: {
      sm: 4,
      md: 4,
      lg: 4,
      xl: 4,
    },
    2: {
      sm: 8,
      md: 8,
      lg: 8,
      xl: 8,
    },
    3: {
      sm: 12,
      md: 12,
      lg: 16,
      xl: 16,
    },
    4: {
      sm: 16,
      md: 20,
      lg: 20,
      xl: 20,
    },
    5: {
      sm: 20,
      md: 20,
      lg: 24,
      xl: 24,
    },
    6: {
      sm: 24,
      md: 28,
      lg: 32,
      xl: 32,
    },
    7: {
      sm: 24,
      md: 32,
      lg: 40,
      xl: 40,
    },
    8: {
      sm: 32,
      md: 40,
      lg: 48,
      xl: 48,
    },
    9: {
      sm: 40,
      md: 48,
      lg: 56,
      xl: 56,
    },
    10: {
      sm: 48,
      md: 56,
      lg: 64,
      xl: 64,
    },
    11: {
      sm: 48,
      md: 56,
      lg: 72,
      xl: 72,
    },
    12: {
      sm: 48,
      md: 56,
      lg: 80,
      xl: 80,
    },
  },
}

export default spacing
