/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledSimpleGridProps } from './SimpleGrid.style.types'

export const SimpleGrid = styled.div((props: Pick<StyledSimpleGridProps, 'theme' | 'justify' | 'verticalAlign' | 'spacing'>): FlattenSimpleInterpolation => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: -${props.theme.spacing.fixed[props.spacing] / 2}px;

  ${props.justify === 'center' && css`
    justify-content: center;
  `}

  ${props.justify === 'right' && css`
    justify-content: flex-end;
  `}

  ${props.verticalAlign === 'top' && css`
    align-items: flex-start;
  `}

  ${props.verticalAlign === 'center' && css`
    align-items: center;
  `}

  ${props.verticalAlign === 'bottom' && css`
    align-items: flex-end;
  `}
`)

export const SimpleGridColumn = styled.div((props: Pick<StyledSimpleGridProps, 'theme' | 'spacing' | 'columns' | 'layout' | 'verticalAlign'>): FlattenSimpleInterpolation => css`
  margin: ${props.theme.spacing.fixed[props.spacing] / 2}px;

  ${props.verticalAlign === 'stretch' && css`
    display: flex;

    & > * {
      flex-grow: 1;
    }
  `}

  ${props.columns && `
    width: calc((100% - ((${props.columns.sm}) * ${props.theme.spacing.fixed[props.spacing]}px)) / ${props.columns.sm});
  `}

  ${props.layout && `
    width: calc(((100% / 12) * ${props.layout.sm}) - ${props.theme.spacing.fixed[props.spacing]}px);
  `}

  ${props.theme.mixins.respondTo.md(css`
    ${props.columns && props.columns.md && css`
      width: calc((100% - ((${props.columns.md}) * ${props.theme.spacing.fixed[props.spacing]}px)) / ${props.columns.md});
    `}

    ${props.layout && props.layout.md && css`
      width: calc(((100% / 12) * ${props.layout.md}) - ${props.theme.spacing.fixed[props.spacing]}px);
    `}
  `)}

  ${props.theme.mixins.respondTo.lg(css`
    ${props.columns && props.columns.lg && css`
      width: calc((100% - ((${props.columns.lg}) * ${props.theme.spacing.fixed[props.spacing]}px)) / ${props.columns.lg});
    `}

    ${props.layout && props.layout.lg && css`
      width: calc(((100% / 12) * ${props.layout.lg}) - ${props.theme.spacing.fixed[props.spacing]}px);
    `}
  `)}

  ${props.theme.mixins.respondTo.xl(css`
    ${props.columns && props.columns.xl && css`
      width: calc((100% - ((${props.columns.xl}) * ${props.theme.spacing.fixed[props.spacing]}px)) / ${props.columns.xl});
    `}

    ${props.layout && props.layout.xl && css`
      width: calc(((100% / 12) * ${props.layout.xl}) - ${props.theme.spacing.fixed[props.spacing]}px);
    `}
  `)}
`)

/* eslint-enable complexity, sonarjs/cognitive-complexity, max-len */
