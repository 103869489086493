import { Colors } from '@vfuk/core-styles/dist/constants/colors.types'
import placeholder from '@vfuk/core-styles/dist/constants/colorPlaceholder'

// opacity for disabled colors
export const opacity = 0.38

const colors: Colors = {
  // primary
  primary1: {
    default: '#E60000',
    hover: '#C30000',
    pressed: '#A10000',
    disabled: `rgba(230, 0, 0, ${opacity})`,
  },
  primary2: {
    default: '#FFFFFF',
    hover: '#FFFFFF',
    pressed: '#FFFFFF',
    disabled: `rgba(255, 255, 255, ${opacity})`,
  },
  primary3: {
    default: '#4A4D4E',
    hover: '#4A4D4E',
    pressed: '#4A4D4E',
    disabled: `rgba(74, 77, 78, ${opacity})`,
  },
  primary4: {
    default: '#25282B',
    hover: '#25282B',
    pressed: '#25282B',
    disabled: `rgba(37, 40, 43, ${opacity})`,
  },

  // secondary
  secondary1: {
    default: '#00697C',
    hover: '#005969',
    pressed: '#004A57',
    disabled: `rgba(0, 105, 124, ${opacity})`,
  },
  secondary2: {
    default: '#5E2750',
    hover: '#5E2750',
    pressed: '#5E2750',
    disabled: `rgba(94, 39, 80, ${opacity})`,
  },
  secondary3: {
    default: '#EB6100',
    hover: '#EB6100',
    pressed: '#EB6100',
    disabled: `rgba(235, 97, 0, ${opacity})`,
  },
  secondary4: {
    default: '#FECB00',
    hover: '#FECB00',
    pressed: '#FECB00',
    disabled: `rgba(254, 203, 0, ${opacity})`,
  },
  secondary5: {
    default: '#008A00',
    hover: '#008A00',
    pressed: '#008A00',
    disabled: `rgba(0, 138, 0, ${opacity})`,
  },
  secondary6: {
    default: '#9C2AA0',
    hover: '#9C2AA0',
    pressed: '#9C2AA0',
    disabled: `rgba(156, 42, 160, ${opacity})`,
  },
  secondary7: {
    default: '#BD0000',
    hover: '#BD0000',
    pressed: '#BD0000',
    disabled: `rgba(189, 0, 0, ${opacity})`,
  },

  // accent
  accent1: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },
  accent2: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },
  accent3: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },
  accent4: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },
  accent5: {
    default: placeholder,
    hover: placeholder,
    pressed: placeholder,
    disabled: placeholder,
  },

  // monochrome
  monochrome1: {
    default: '#FFFFFF',
    hover: '#D9D9D9',
    pressed: '#B3B3B3',
    disabled: `rgba(255, 255, 255, ${opacity})`,
  },
  monochrome2: {
    default: '#F2F2F2',
    hover: '#F2F2F2',
    pressed: '#F2F2F2',
    disabled: `rgba(242, 242, 242, ${opacity})`,
  },
  monochrome3: {
    default: '#BEBEBE',
    hover: '#BEBEBE',
    pressed: '#BEBEBE',
    disabled: `rgba(190, 190, 190, ${opacity})`,
  },
  monochrome4: {
    default: '#7E7E7E',
    hover: '#7E7E7E',
    pressed: '#7E7E7E',
    disabled: `rgba(126, 126, 126, ${opacity})`,
  },
  monochrome5: {
    default: '#262626',
    hover: '#262626',
    pressed: '#262626',
    disabled: `rgba(38, 38, 38, ${opacity})`,
  },
  monochrome6: {
    default: '#0D0D0D',
    hover: '#313131',
    pressed: '#565656',
    disabled: `rgba(13, 13, 13, ${opacity})`,
  },

  // transparency
  opacity1: {
    default: 'rgba(255, 255, 255, .15)',
    hover: 'rgba(255, 255, 255, .15)',
    pressed: 'rgba(255, 255, 255, .15)',
    disabled: 'rgba(255, 255, 255, .15)',
  },
  opacity2: {
    default: 'rgba(255, 255, 255, .30)',
    hover: 'rgba(255, 255, 255, .30)',
    pressed: 'rgba(255, 255, 255, .30)',
    disabled: 'rgba(255, 255, 255, .30)',
  },
  opacity3: {
    default: 'rgba(255, 255, 255, .75)',
    hover: 'rgba(255, 255, 255, .75)',
    pressed: 'rgba(255, 255, 255, .75)',
    disabled: 'rgba(255, 255, 255, .75)',
  },
  opacity4: {
    default: 'rgba(13, 13, 13, .15)',
    hover: 'rgba(13, 13, 13, .15)',
    pressed: 'rgba(13, 13, 13, .15)',
    disabled: 'rgba(13, 13, 13, .15)',
  },
  opacity5: {
    default: 'rgba(13, 13, 13, .30)',
    hover: 'rgba(13, 13, 13, .30)',
    pressed: 'rgba(13, 13, 13, .30)',
    disabled: 'rgba(13, 13, 13, .30)',
  },
  opacity6: {
    default: 'rgba(13, 13, 13, .75)',
    hover: 'rgba(13, 13, 13, .75)',
    pressed: 'rgba(13, 13, 13, .75)',
    disabled: 'rgba(13, 13, 13, .75)',
  },

  // gradients
  gradient1: {
    default: 'to right, #820000, #E60000',
    hover: 'to right, #820000, #E60000',
    pressed: 'to right, #820000, #E60000',
    disabled: 'to right, #820000, #E60000',
  },
  gradient2: {
    default: 'to right, #222222, #25282B',
    hover: 'to right, #222222, #25282B',
    pressed: 'to right, #222222, #25282B',
    disabled: 'to right, #222222, #25282B',
  },
  gradient3: {
    default: 'to right, #F4F4F4, #FAFAFA',
    hover: 'to right, #F4F4F4, #FAFAFA',
    pressed: 'to right, #F4F4F4, #FAFAFA',
    disabled: 'to right, #F4F4F4, #FAFAFA',
  },

  // states
  error: {
    default: '#BD0000',
    hover: '#BD0000',
    pressed: '#BD0000',
    disabled: '#BD0000',
  },
  warn: {
    default: '#EB6100',
    hover: '#EB6100',
    pressed: '#EB6100',
    disabled: '#EB6100',
  },
  success: {
    default: '#008A00',
    hover: '#007500',
    pressed: '#006100',
    disabled: '#008A00',
  },
  info: {
    default: '#005EA5',
    hover: '#005EA5',
    pressed: '#005EA5',
    disabled: '#005EA5',
  },
  focus: {
    default: '#0096AD',
    hover: '#0096AD',
    pressed: '#0096AD',
    disabled: '#0096AD',
  },
  disabled: {
    default: '#999999',
    hover: '#999999',
    pressed: '#999999',
    disabled: '#999999',
  },
  incomplete: {
    default: '#262626',
    hover: '#262626',
    pressed: '#262626',
    disabled: '#262626',
  },
}

export default colors
