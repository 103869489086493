/* eslint-disable max-len */
import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { ButtonTheme, StateProps } from './Button.theme.types'

const buttonLocal = (theme: Theme): ButtonTheme => {
  const commonBorderStyle = {
    width: theme.border.width[2],
    style: theme.border.style[1],
    colors: {
      outerBorderColor: 'transparent',
    },
  }

  const stateSuccess: StateProps = {
    color: theme.color.monochrome1.default,
    backgroundColor: theme.color.success.default,
    iconAppearance: 'primary',
    inverseIcon: true,
    border: commonBorderStyle,
    hover: {
      color: theme.color.monochrome1.default,
      backgroundColor: theme.color.success.hover,
      iconAppearance: 'primary',
    },
    pressed: {
      color: theme.color.monochrome1.default,
      backgroundColor: theme.color.success.pressed,
    },
  }

  const state = {
    success: stateSuccess,
    selected: stateSuccess,
  }

  return {
    appearance: {
      primary: {
        color: theme.color.monochrome1.default,
        backgroundColor: theme.color.primary1.default,
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.pressed,
        },
        iconAppearance: 'primary',
        inverseIcon: true,
        border: commonBorderStyle,
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.hover,
          iconAppearance: 'secondary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome1.disabled,
            backgroundColor: theme.color.primary1.disabled,
            iconAppearance: 'primary',
            inverseIcon: true,
            border: commonBorderStyle,
            hover: {
              color: theme.color.monochrome1.disabled,
              backgroundColor: theme.color.primary1.disabled,
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: theme.inverseColor.primary1.default,
          pressed: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.pressed,
          },
          iconAppearance: 'primary',
          inverseIcon: true,
          border: commonBorderStyle,
          hover: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.hover,
            iconAppearance: 'secondary',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.disabled,
              backgroundColor: theme.inverseColor.primary1.disabled,
              iconAppearance: 'primary',
              inverseIcon: true,
              border: commonBorderStyle,
              hover: {
                color: theme.inverseColor.monochrome6.disabled,
                backgroundColor: theme.inverseColor.primary1.disabled,
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      secondary: {
        color: theme.color.monochrome1.default,
        backgroundColor: theme.color.monochrome6.default,
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.pressed,
        },
        iconAppearance: 'primary',
        inverseIcon: true,
        border: commonBorderStyle,
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.hover,
          iconAppearance: 'secondary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome1.disabled,
            backgroundColor: theme.color.monochrome6.disabled,
            iconAppearance: 'primary',
            inverseIcon: true,
            border: commonBorderStyle,
            hover: {
              color: theme.color.monochrome1.disabled,
              backgroundColor: theme.color.monochrome6.disabled,
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome1.default,
          backgroundColor: theme.inverseColor.monochrome6.default,
          pressed: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.pressed,
          },
          iconAppearance: 'primary',
          inverseIcon: false,
          border: commonBorderStyle,
          hover: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.hover,
            iconAppearance: 'primary',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome1.disabled,
              backgroundColor: theme.inverseColor.monochrome6.disabled,
              iconAppearance: 'primary',
              inverseIcon: false,
              border: commonBorderStyle,
              hover: {
                color: theme.inverseColor.monochrome1.disabled,
                backgroundColor: theme.inverseColor.monochrome6.disabled,
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      alt1: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.pressed,
        },
        iconAppearance: 'primary',
        inverseIcon: false,
        border: {
          ...commonBorderStyle,
          colors: {
            outerBorderColor: theme.color.monochrome6.default,
          },
        },
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.hover,
          iconAppearance: 'primary',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome6.disabled,
            backgroundColor: 'transparent',
            iconAppearance: 'primary',
            inverseIcon: false,
            border: {
              ...commonBorderStyle,
              colors: {
                outerBorderColor: theme.color.monochrome6.disabled,
              },
            },
            hover: {
              color: theme.color.monochrome6.disabled,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          pressed: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.pressed,
          },
          iconAppearance: 'primary',
          inverseIcon: true,
          border: {
            ...commonBorderStyle,
            colors: {
              outerBorderColor: theme.color.monochrome1.default,
            },
          },
          hover: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.hover,
            iconAppearance: 'alt1',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.disabled,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
              inverseIcon: true,
              border: {
                ...commonBorderStyle,
                colors: {
                  outerBorderColor: theme.color.monochrome1.disabled,
                },
              },
              hover: {
                color: theme.inverseColor.monochrome6.disabled,
                backgroundColor: 'transparent',
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
      alt2: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.pressed,
        },
        iconAppearance: 'primary',
        inverseIcon: false,
        border: {
          ...commonBorderStyle,
          colors: {
            outerBorderColor: theme.color.primary1.default,
          },
        },
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.hover,
          iconAppearance: 'alt1',
        },
        state: {
          ...state,
          disabled: {
            color: theme.color.monochrome6.disabled,
            backgroundColor: 'transparent',
            iconAppearance: 'primary',
            inverseIcon: false,
            border: {
              ...commonBorderStyle,
              colors: {
                outerBorderColor: theme.color.primary1.disabled,
              },
            },
            hover: {
              color: theme.color.monochrome6.disabled,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
            },
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          pressed: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.pressed,
          },
          iconAppearance: 'primary',
          inverseIcon: true,
          border: {
            ...commonBorderStyle,
            colors: {
              outerBorderColor: theme.inverseColor.primary1.default,
            },
          },
          hover: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.hover,
            iconAppearance: 'alt1',
          },
          state: {
            ...state,
            disabled: {
              color: theme.inverseColor.monochrome6.disabled,
              backgroundColor: 'transparent',
              iconAppearance: 'primary',
              inverseIcon: true,
              border: {
                ...commonBorderStyle,
                colors: {
                  outerBorderColor: theme.inverseColor.primary1.disabled,
                },
              },
              hover: {
                color: theme.inverseColor.monochrome6.disabled,
                backgroundColor: 'transparent',
                iconAppearance: 'primary',
              },
            },
          },
        },
      },
    },
    inverse: false,
    borderRadius: theme.border.radius[2],
    focus: theme.color.focus.default,
  }
}

export default buttonLocal
