import React, { ReactElement, FC } from 'react'

import Container from '@vfuk/core-container'
import Paragraph from '@vfuk/core-paragraph'

import * as Styled from './styles/ContainerWithLabel.style'
import getHighlight from './helpers/getHighlight'

import { ContainerWithLabelProps } from './ContainerWithLabel.types'

const ContainerWithLabel: FC<ContainerWithLabelProps> = ({
  children,
  appearance = 'primary',
  label,
  to,
  href,
  dataAttributes,
  highlight,
  paddingLevel = 2,
  level = 1,
  elevation = true,
  showOuterTopPadding = false,
  allowOverflow,
  onClick,
  state,
}: ContainerWithLabelProps): ReactElement => {
  return (
    <Styled.ContainerWithLabel hasLabel={!!label} level={level} showOuterTopPadding={showOuterTopPadding}>
      <If condition={label}>
        <Styled.Header>
          <Styled.LabelContainer appearance={appearance} level={level} state={state}>
            <Paragraph weight={3} inverse>
              {label}
            </Paragraph>
          </Styled.LabelContainer>
        </Styled.Header>
      </If>
      <Container
        elevation={elevation}
        paddingLevel={paddingLevel}
        appearance='primary'
        to={to}
        href={href}
        dataAttributes={dataAttributes}
        allowOverflow={allowOverflow}
        highlight={getHighlight(label, appearance, highlight)}
        onClick={onClick}
        state={state}
      >
        {children}
      </Container>
    </Styled.ContainerWithLabel>
  )
}

export default ContainerWithLabel
