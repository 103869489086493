import React, { PureComponent, ReactNode } from 'react'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/SelectInput.style'

import { SelectInputProps, OnChangeEvent, OnBlurEvent } from './SelectInput.types'

export default class SelectInput extends PureComponent<SelectInputProps> {
  public static defaultProps: Partial<SelectInputProps> = {
    required: false,
  }

  private handleOnChange = (event: OnChangeEvent): void => {
    if (this.props.onChange && this.props.state !== 'disabled') this.props.onChange(event)
  }

  private handleOnBlur = (event: OnBlurEvent): void => {
    if (this.props.onBlur && this.props.state !== 'disabled') this.props.onBlur(event)
  }

  public render(): ReactNode {
    return (
      <Styled.SelectInputWrapper>
        <Styled.SelectInput
          state={this.props.state}
          aria-disabled={this.props.state === 'disabled'}
          id={this.props.id}
          name={this.props.name ? this.props.name : this.props.id}
          required={this.props.required}
          value={this.props.value}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          aria-describedby={this.props.describedBy}
          {...this.props.dataAttributes}
        >
          <If condition={this.props.placeholder}>
            <Styled.Option selected={!this.props.value} value={this.props.placeholder} disabled>
              {this.props.placeholder}
            </Styled.Option>
          </If>
          {this.props.options.map((option) => {
            const optionText = option.disabled && option.helpText ? `${option.text} ${option.helpText}` : option.text

            return (
              <Styled.Option
                key={option.value || option.text}
                value={option.value || option.text}
                disabled={this.props.state === 'disabled' || option.disabled}
              >
                {optionText}
              </Styled.Option>
            )
          })}
        </Styled.SelectInput>
        <Styled.IconContainer state={this.props.state}>
          <Icon name='chevron-down' group='system' size={1} />
        </Styled.IconContainer>
      </Styled.SelectInputWrapper>
    )
  }
}
