import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledProgressBarProps } from './ProgressBar.style.types'

import localThemes from '../themes/ProgressBar.theme';

type StyledProgressContainer = Pick<StyledProgressBarProps,'inverse' | 'topText' | 'theme' >
export const ProgressBarContainer = styled.div<StyledProgressContainer>((props: StyledProgressContainer): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: flex-end;
    ${props.theme.mixins.spacing('gap', 3)}
    ${(!props.topText?.left && !props.topText?.right)  && css`
        align-items: center;
    `}
`)

type StyledProgressBarSection = Pick<StyledProgressBarProps, 'theme' >
export const ProgressBarSection = styled.div<StyledProgressBarSection>((props: StyledProgressBarSection): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${props.theme.mixins.spacing('gap', 2)}
`)

type StyledProgressBarTrack = Pick<StyledProgressBarProps, 'theme' | 'inverse' >
export const ProgressBarTrack = styled.div<StyledProgressBarTrack>((props: StyledProgressBarTrack): FlattenSimpleInterpolation => css`
    display: flex;
    width: 100%;
    ${props.theme.mixins.responsiveSizing('height', 2)}
    overflow: hidden;
    background-color: ${props.theme.color.monochrome3.default};
    border-radius: ${props.theme.border.radius[3]};
    ${props.inverse && css`
        border-color: ${props.theme.inverseColor.monochrome3.default};
    `}
`)

type StyledProgressBar = Pick<StyledProgressBarProps, 'theme' | 'inverse' | 'width' | 'appearance'>
export const ProgressBar = styled.div<StyledProgressBar>((props: StyledProgressBar): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme!);
  return css`
  display: inline-flex;
  width: ${props.width}%;
  flex-direction: column;
  justify-content: center;
  background-color: ${localTheme.appearance[props.appearance!].backgroundColor};
  border-right: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${props.theme.color.monochrome2.default};
  
  ${props.inverse && css`
      background-color: ${localTheme.appearance[props.appearance!].inverse.backgroundColor};
      border-color: ${props.theme.inverseColor.monochrome2.default};
  `}

  ${props.width >= 100 && css`
      border: none;
  `}
`
})



type StyledTopText = Pick<StyledProgressBarProps, 'theme' >
export const TopText = styled.div<StyledTopText>((props: StyledTopText): FlattenSimpleInterpolation => css`
  display: flex;
  justify-content: space-between;
  ${props.theme.mixins.spacing('gap', 2)}
`)

export const IconWithBackgroundWrapper = styled.div((): FlattenSimpleInterpolation => css`
  display: inline-flex;
`)
