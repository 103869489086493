import { IconRules } from '@vfuk/core-styles/dist/themes/themes.types'
import SourceSystemIcons from '@vfuk/source-system-icons/dist/ruleSet'
import SourceHiFiDarkIcons from '@vfuk/source-hifi-dark-icons/dist/ruleSet'
import SourceHiFiLightIcons from '@vfuk/source-hifi-light-icons/dist/ruleSet'
import SourceMidRenderIcons from '@vfuk/source-mid-render-icons/dist/ruleSet'
import SourceTobiIcons from '@vfuk/source-tobi-icons/dist/ruleSet'
import SourceSocialIcons from '@vfuk/source-social-icons/dist/ruleSet'
import SourceStateIcons from '@vfuk/source-state-icons/dist/ruleSet'
import UKLegacyIcons from '@vfuk/uk-legacy-icons/dist/ruleSet'
import BrandHiFiIcons from '@vfuk/brand-hifi-icons/dist/ruleSet'
import SourcePaymentIcons from '@vfuk/source-payment-icons/dist/ruleSet'

const iconRules: IconRules = {
  system: {
    assetLocation: 'SourceSystemIcons',
    properties: SourceSystemIcons,
  },
  state: {
    assetLocation: 'SourceStateIcons',
    properties: SourceStateIcons,
  },
  hifiDark: {
    assetLocation: 'SourceHiFiDarkIcons',
    properties: SourceHiFiDarkIcons,
  },
  hifiLight: {
    assetLocation: 'SourceHiFiLightIcons',
    properties: SourceHiFiLightIcons,
  },
  mid: {
    assetLocation: 'SourceMidRenderIcons',
    properties: SourceMidRenderIcons,
  },
  social: {
    assetLocation: 'SourceSocialIcons',
    properties: SourceSocialIcons,
  },
  chatbot: {
    assetLocation: 'SourceTobiIcons',
    properties: SourceTobiIcons,
  },
  ukLegacy: {
    assetLocation: 'UkLegacyIcons',
    properties: UKLegacyIcons,
  },
  brandHifi: {
    assetLocation: 'BrandHiFiIcons',
    properties: BrandHiFiIcons,
  },
  payment: {
    assetLocation: 'SourcePaymentIcons',
    properties: SourcePaymentIcons,
  },
}

export default iconRules
