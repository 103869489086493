import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledIconWithBackgroundProps } from './IconWithBackground.style.types'

import localThemes from '../themes/IconWithBackground.theme'


export const IconWithBackground = styled.div<StyledIconWithBackgroundProps>((props: StyledIconWithBackgroundProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme);

  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    height: ${localTheme.size[props.size!].sm.containerSize}px;
    width: ${localTheme.size[props.size!].sm.containerSize}px;
    border-radius: ${props.theme.border.radius[2]};
    background-color: ${localTheme.appearance[props.appearance!].backgroundColor};

    ${props.inverse && css`
      background-color: ${localTheme.appearance[props.appearance!].inverse.backgroundColor};
    `}

    ${props.theme.mixins.respondTo.md(css`
      height: ${localTheme.size[props.size!].md.containerSize}px;
      width: ${localTheme.size[props.size!].md.containerSize}px;
    `)}

    ${props.shape === 'circle' && css`
      border-radius: 50%;
    `}
  `
})
