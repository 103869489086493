import { Theme } from '@vfuk/core-styles/dist/themes/themes.types'

import { ProgressBarTheme } from './ProgressBar.theme.types'

const progressBarLocal = (theme: Theme): ProgressBarTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.primary1.default,
        inverse: {
          backgroundColor: theme.inverseColor.primary1.default,
        },
      },
      secondary: {
        backgroundColor: theme.color.secondary1.default,
        inverse: {
          backgroundColor: theme.inverseColor.secondary1.default,
        },
      },
    },
  }
}

export default progressBarLocal
