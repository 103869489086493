/* eslint-disable complexity, no-nested-ternary, @vfuk/rules/js-no-complex-ternary */
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import { StyledContainerProps } from './Container.style.types'

import localTheme from '../themes/Container.theme'

export const Container = styled(Interaction)((props: StyledContainerProps): FlattenSimpleInterpolation => {
  const appearanceFromProp = localTheme(props.theme).appearance[props.appearance!]

  return css`
    height: 100%;
    color: ${appearanceFromProp.color};
    background: ${appearanceFromProp.backgroundColor};
    box-sizing: border-box;
    background-clip: padding-box;
    border-radius: ${props.theme.border.radius[2]};
    position: relative;

    ${props.theme.mixins.elevation(props.dropShadow)}

    ${props.state && css`
      &::after {
        content: '';
        z-index: 1;
        border-style: ${props.theme.border.style[1]};
        border-width: ${props.theme.border.width[2]};
        border-color: ${appearanceFromProp.state[props.state].borderColor};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: ${props.theme.border.radius[2]};
        pointer-events: none;
      }
    `}

    ${props.highlight && css`
      &::after {
        content: '';
        z-index: 1;
        border-style: ${props.theme.border.style[1]};
        border-width: ${props.theme.border.width[appearanceFromProp.highlight[props.highlight.appearance].level[props.highlight.level].borderWidth]};
        border-color: ${appearanceFromProp.highlight[props.highlight.appearance].borderColor};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: ${props.theme.border.radius[2]};
        pointer-events: none;
      }
    `}

    ${props.backgroundImage && css`
      color: ${props.theme.color.monochrome1.default};

      [class*='Icon'] {
        * {
          ${props.theme.mixins.iconAppearance(props.theme.color.monochrome1.default)};
        }
      }

      ${props.theme.mixins.bgImage(props.backgroundImage)}
    `}

    ${!props.allowOverflow && css`
      overflow: hidden;
    `}

    [class*=Overlay] {
      border-radius: ${props.theme.border.radius[2]};
    }

    ${props.paddingLevel && props.paddingLevel > 0 && css`
      padding: ${props.theme.spacing.responsive[localTheme(props.theme).paddingLevel[props.paddingLevel].spacingValue].sm}px;

      ${props.theme.mixins.respondTo.md(css`
      padding: ${props.theme.spacing.responsive[localTheme(props.theme).paddingLevel[props.paddingLevel].spacingValue].md}px;
      `)}

      ${props.theme.mixins.respondTo.lg(css`
      padding: ${props.theme.spacing.responsive[localTheme(props.theme).paddingLevel[props.paddingLevel].spacingValue].lg}px;
      `)}
    `}
  `
})

export const ContainerContent = styled.div((): FlattenSimpleInterpolation => {
  return css`
    height: 100%;
    position: relative;
    z-index: 2;
  `
})
