import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { StyledStateNotificationProps } from './StateNotification.style.types'

type StateNotificationProps = Pick<StyledStateNotificationProps, 'theme'>
export const StateNotification = styled.div((): FlattenSimpleInterpolation => css`
  display: flex;
`)

export const IconWrapper = styled.div((props: StyledStateNotificationProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.advancedSpacing('padding', [4])};
  background: ${props.theme.color[props.state].default};
`)

type ContentWrapperProps = Pick<StyledStateNotificationProps, 'theme'>
export const ContentWrapper = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  ${props.theme.mixins.advancedSpacing('padding', [4])};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
`)

export const NotificationWrapper = styled.div((): FlattenSimpleInterpolation => css`
  width: 100%;
  display: flex;
`)

export const CloseButton = styled.div((props: ContentWrapperProps): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${props.theme.mixins.advancedSpacing('padding', [4, 4, 0, 0])};
  align-self: flex-start;
`)

export const ButtonWrapper = styled.div((props: Pick<StateNotificationProps, 'theme'>): FlattenSimpleInterpolation => css`
  display: flex;
  align-items: flex-start;
  ${props.theme.mixins.advancedSpacing('padding', [4, 4, 0])};

  ${props.theme.mixins.respondTo.md(css`
    flex-direction: row;
  `)}
`)
