import React, { FC, ReactElement } from 'react'

import Overlay from '@vfuk/core-overlay'

import * as Styled from './styles/Container.style'

import { ContainerProps } from './Container.types'

const Container: FC<ContainerProps> = ({
  children,
  appearance = 'primary',
  highlight,
  elevation = false,
  id,
  backgroundImage,
  enableOverlay = true,
  state,
  paddingLevel = 3,
  onClick,
  to,
  href,
  dataAttributes,
  allowOverflow = true,
}: ContainerProps): ReactElement => {
  const highlightProp = !state && !backgroundImage && highlight

  return (
    <Styled.Container
      id={id}
      state={state}
      dropShadow={elevation!}
      appearance={appearance!}
      highlight={highlightProp ? highlight : undefined}
      backgroundImage={backgroundImage}
      paddingLevel={paddingLevel}
      onInteraction={onClick}
      to={to}
      href={href}
      dataAttributes={dataAttributes}
      allowOverflow={backgroundImage ? false : allowOverflow}
    >
      <If condition={enableOverlay && backgroundImage}>
        <Overlay show position='absolute' zIndex={1} />
      </If>
      <Styled.ContainerContent>{children}</Styled.ContainerContent>
    </Styled.Container>
  )
}

export default Container
